import Vue from 'vue'
import App from './App.vue'
import router from "./router/index"
import $ from 'jquery'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import './assets/css/global.css'
import VueAwesomeSwiper from 'vue-awesome-swiper';
import rem from "./assets/js/rem"
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import axios from 'axios'

Vue.use(ElementUI)
Vue.use(VueAwesomeSwiper)

Vue.config.productionTip = false
Vue.prototype.$http = axios
axios.defaults.baseURL = '/api'
//axios.defaults.baseURL = 'http://dev.fdsure.com:9525'



new Vue({
  render: h => h(App),
  router
}).$mount('#app')
