<template>
    <div class="business">
        <Nav-header class="nav-title"></Nav-header>
        <div class="banner-div">
            <img src="../assets/imgs/risk-banner.png" alt="">
            <div class="banner-info">
                <div>RISK MANAGEMENT</div>
                <div>风险控制</div>
                <div>
                全流程的制度风控保障，严控销售各环节风险，确保客户、合作伙伴和平台安全。
                </div>
            </div>
        </div>
        <div class="risk-div">
            <div class="title">风险控制</div>
            <div class="title-eng">RISK</div>
            <div class="info">
                全流程的制度<span>风控保障</span>，严控销售<span>各环节风险</span>，确保客户、合作伙伴和<span>平台安全</span>。
            </div>
            <div class="risk-list">
                <div class="risk-item">
                    <div>产品库制度</div>
                    <img src="../assets/imgs/risk01.png" alt="">
                    <div class="detail">
                        规范<span>合法合规</span>代销产品范围<br/>
                        制定公募、私募等各类产品遴选标准<br/>
                        制定风险事故追责机制<br/>
                        <span>多元化配置，充分分散风险</span>
                    </div>
                </div>
                <div class="risk-item">
                    <div>管理人审慎调查</div>
                    <img src="../assets/imgs/risk02.png" alt="">
                    <div class="detail">
                        <span>严选优选</span>合作伙伴<br/>
                        制定管理人<span>审慎调查</span>制度<br/>
                        <span>全面掌握管理人</span>诚信情况、经营管理能力、<br/>投资管理能力、适当性制度、客户服务水平等
                    </div>
                </div>
                <div class="risk-item">
                    <div>尽职调查制度</div>
                    <img src="../assets/imgs/risk03.png" alt="">
                    <div class="detail">
                        制定合作机构<span>尽职调查指引</span><br/>
                        实现合作机构全面调查<br/>
                        确定<span>尽职调查</span>要求及方法<br/>
                        严格要求尽职调查内容<span>全面覆盖</span>，确定尽调分工和责任
                    </div>
                </div>
                <div class="risk-item">
                    <div>二级风控制度</div>
                    <img src="../assets/imgs/risk04.png" alt="">
                    <div class="detail">
                        一级产品会<span>评审</span>，二级产品上架<span>投决</span><br/>
                        评审人员覆盖<span>风控、财务、市场、运营</span><br/>
                        规范产品立项、发行和投决流程，严格审核标准
                    </div>
                </div>
                <div class="risk-item">
                    <div>销售环节风控制度</div>
                    <img src="../assets/imgs/risk05.png" alt="">
                    <div class="detail">
                        对<span>股东与合作</span>方、基金账户、交易、清算、营销与客<br/>
                        服、销售适当性、技术安全、员工道德、合规各环节<br/>
                        实施严格的<span>风险控制</span>
                    </div>
                </div>
                <div class="risk-item">
                    <div>反洗钱风控制度</div>
                    <img src="../assets/imgs/risk06.png" alt="">
                    <div class="detail">
                        <span>客户身份识别</span>（以及重新识别）与风险等级划分<br/>
                        客户身份资料及交易记录保存<br/>
                        大额交易和可疑交易报告<br/>
                        设置专门的<span>反洗钱工作</span>领导部门
                    </div>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>
<script>
    import NavHeader from '../components/nav'
    import Footer from '../components/footer'
    export default {
        data(){
            return{
               
            }
        },
        components:{
            NavHeader,
            Footer
        },
        methods:{
        }
    }
</script>
<style lang="less" scoped>
@media screen and (max-width: 768px){
     .business{
         .banner-div{
            position: relative;
            img{
                width: 100%;
                height: 5rem;
            }
            .banner-info{
                position: absolute;
                top:20%;
                left: 8%;
                color: #fff;
                div{
                    &:first-child{
                    font-size: 18px;
                    }
                    &:nth-child(2){
                    font-size: 14px;
                    }
                    &:nth-child(3){
                    font-size: 12px;
                    margin-top: 0.1rem;
                    }
                }
            }
        }
        .risk-div{
             .title{
                font-size: 18px;
                font-weight: bold;
                color: #122C5B;
                text-align: center;
                margin-top: 0.3rem;
            }
            .title-eng{
                font-size: 14px;
                color: #4676B1;
                text-align: center;
                margin: 0 0 0.2rem;
            }
            .info{
                margin: 0.2rem 8%;
                font-size: 12px;
                color: #6C727E;
                text-align: center;
                span{
                    font-size: 14px;
                    color: #6A98D1;
                    font-weight: bold;
                }
            }
            .risk-list{
                display: flex;
                flex-wrap: wrap;
                margin: 0.4rem 4%;
                .risk-item{
                    width: 50%;
                    padding:0 0.2rem;
                    text-align: center;
                    img{
                        width: 5rem;
                    }
                    .detail{
                        color: #6C727E;
                        font-size: 12px;
                        margin: 0.2rem 0;
                        text-align: center;
                        span{
                            color: #6A98D1;
                            font-size: 12px;
                        }
                    }
                    >div{
                        &:first-child{
                            color: #546DB9;
                            font-size: 12px;
                            padding: 0.15rem 0;
                            border-bottom: 1px solid #E2EBF2;
                            font-weight: bold;
                            width: 5rem;
                            margin:0 auto 0.2rem;
                            text-align: left;
                        }
                    }
                }
            }
        }
    }
}
@media screen and (min-width: 768px){
     .business{
        .banner-div{
            position: relative;
            img{
                width: 100%;
                height: 5rem;
            }
            .banner-info{
                position: absolute;
                top:30%;
                left: 8%;
                color: #fff;
                div{
                    &:first-child{
                    font-size: 26px;
                    }
                    &:nth-child(2){
                    font-size: 18px;
                    }
                    &:nth-child(3){
                    font-size: 16px;
                    margin-top: 0.1rem;
                    }
                }
            }
        }
        .risk-div{
            .title{
                font-size: 22px;
                font-weight: bold;
                color: #122C5B;
                text-align: center;
                margin-top: 0.2rem;
            }
            .title-eng{
                font-size: 16px;
                color: #4676B1;
                text-align: center;
                margin: 0 0 0.2rem;
            }
            .info{
                margin: 0.2rem 8%;
                font-size: 14px;
                color: #6C727E;
                 text-align: center;
                span{
                    font-size: 16px;
                    color: #6A98D1;
                    font-weight: bold;
                }
            }
            .risk-list{
                display: flex;
                flex-wrap: wrap;
                margin: 0.2rem 8%;
                .risk-item{
                    width: 50%;
                    padding:0 0.2rem;
                    text-align: center;
                    img{
                        width: 4rem;
                    }
                    .detail{
                        color: #6C727E;
                        font-size: 12px;
                        margin: 0.2rem 0;
                        line-height: 0.3rem;
                        text-align: center;
                        span{
                            color: #6A98D1;
                            font-size: 14px;
                        }
                    }
                    >div{
                        &:first-child{
                            color: #546DB9;
                            font-size: 14px;
                            padding: 0.15rem 0;
                            border-bottom: 1px solid #E2EBF2;
                            font-weight: bold;
                            width: 4rem;
                            margin:0 auto 0.2rem;
                            text-align: left;
                        }
                    }
                }
            }
        }
    }
}
@media screen and (min-width: 1200px){
    .business{
         .banner-div{
            position: relative;
            img{
                width: 100%;
                height: 3rem;
            }
            .banner-info{
                position: absolute;
                top:40%;
                left: 8%;
                color: #fff;
                div{
                    &:first-child{
                    font-size: 26px;
                    }
                    &:nth-child(2){
                    font-size: 18px;
                    }
                    &:nth-child(3){
                    font-size: 16px;
                    margin-top: 0.1rem;
                    }
                }
            }
        }
        .risk-div{
            .title{
                font-size: 24px;
                font-weight: bold;
                color: #122C5B;
                text-align: center;
                margin-top: 0.2rem;
            }
            .title-eng{
                font-size: 16px;
                color: #4676B1;
                text-align: center;
                margin: 0 0 0.2rem;
            }
            .info{
                margin: 0.2rem 8%;
                font-size: 14px;
                color: #6C727E;
                 text-align: center;
                span{
                    font-size: 16px;
                    color: #6A98D1;
                    font-weight: bold;
                }
            }
            .risk-list{
                display: flex;
                flex-wrap: wrap;
                margin: 0.2rem 8%;
                .risk-item{
                    width: 50%;
                    padding:0 0.2rem;
                    text-align: center;
                    img{
                        width: 3rem;
                    }
                    .detail{
                        color: #6C727E;
                        font-size: 14px;
                        margin: 0.2rem 0;
                        line-height: 0.18rem;
                        text-align: center;
                        span{
                            color: #6A98D1;
                            font-size: 16px;
                        }
                    }
                    >div{
                        &:first-child{
                            color: #546DB9;
                            font-size: 16px;
                            padding: 0.15rem 0;
                            border-bottom: 1px solid #E2EBF2;
                            font-weight: bold;
                            width: 3rem;
                            margin:0 auto 0.2rem;
                            text-align: left;
                        }
                    }
                }
            }
        }
    }
}
.business{
    padding-top: 70px;
    .nav-title{
        position: fixed;
        top:0;
        z-index: 100;
    }
}
</style>