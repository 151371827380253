<template>
    <div class="finance-contain">
        <Nav-header class="nav-title"></Nav-header>
        <div class="banner-div">
            <img src="../assets/imgs/money-bg.png" alt="">
            <div class="banner-info">
                <div>INTELLIGENCE</div>
                <div>金融资质</div>
                <div>
                拥有全国保险代理牌照、互联网保险销售资质及基金销售牌照
                </div>
          </div>
        </div>
        <div class="contain">
            <div class="title">金融资质</div>
            <div class="title-eng">INTELLIGENCE</div>
            <div class="info">我们拥有全国保险代理牌照、互联网保险销售资质及基金销售牌照。</div>
            <div class="item-div">
                <img src="../assets/imgs/certificate1.png" alt="">
                <div>《中华人民共和国经营证券期货业务许可证》</div>
            </div>
            <div class="cerifi-bg">
                <div class="item-div">
                    <img src="../assets/imgs/certificate2.png" alt="">
                    <div>《经营保险代理业务许可证》</div>
                </div>
                <div class="item-div">
                    <img src="../assets/imgs/certificate3.png" alt="">
                    <div>互联网保险销售资质
                        <span>图片摘自保险业协会官网</span>
                    </div>
                </div>
            </div>
        </div>
        <Footer></Footer>  
    </div>
</template>
<script>
    import NavHeader from '../components/nav'
    import Footer from '../components/footer'
    export default {
        data(){
            return{

            }
        },
        components:{
            NavHeader,
            Footer
        },
        methods:{

        }
    }
</script>
<style lang="less" scoped>
@media screen and (max-width: 768px){
    .finance-contain{
        .banner-div{
            position: relative;
            img{
                width: 100%;
                height: 5rem;
            }
            .banner-info{
        position: absolute;
        top:20%;
        left: 8%;
        color: #fff;
        div{
            &:first-child{
            font-size: 18px;
            }
            &:nth-child(2){
            font-size: 14px;
            }
            &:nth-child(3){
            font-size: 12px;
            margin-top: 0.1rem;
            }
        }
        }
        }
        .contain{
            padding: 0.5rem 0 0;
            .title{
                font-size: 20px;
                font-weight: bold;
                color: #122C5B;
                text-align: center;
            }
            .title-eng{
                font-size: 14px;
                color: #4676B1;
                text-align: center;
                margin: 0 0 0.2rem;
            }
            .info{
                font-size: 12px;
                color: #6C727E;
                margin: 0.2rem 0;
                text-align: center;
            }
            .item-div{
                width: 70%;
                text-align: center;
                margin: auto;
                img{
                    width: 100%;
                    height: 100%;
                }
                div{
                    font-size: 12px;
                    color: #6C727E;
                    margin: 0.3rem 0;
                    span{
                        display: block;
                        color: #A2A2A2;
                    }
                }
            }
            .cerifi-bg{
                background-image: url('../assets/imgs/cerifi-bg.png');
                background-size: 100% 100%;
                padding-bottom: 0.2rem;
            }
        }
    }
}
@media screen and (min-width: 768px){
    .finance-contain{
    .banner-div{
        position: relative;
        img{
            width: 100%;
            height: 4rem;
        }
        .banner-info{
            position: absolute;
            top:40%;
            left: 8%;
            color: #fff;
            div{
                &:first-child{
                font-size: 26px;
                }
                &:nth-child(2){
                font-size: 18px;
                }
                &:nth-child(3){
                font-size: 16px;
                margin-top: 0.1rem;
                }
            }
            }
    }
     .contain{
         padding: 0.3rem 0 0;
              .title{
                font-size: 24px;
                font-weight: bold;
                color: #122C5B;
                text-align: center;
            }
            .title-eng{
                font-size: 16px;
                color: #4676B1;
                text-align: center;
                margin: 0 0 0.2rem;
            }
            .info{
                font-size: 14px;
                color: #6C727E;
                margin: 0.2rem 0;
                text-align: center;
            }
            .item-div{
                width: 60%;
                text-align: center;
                margin: auto;
                img{
                    width: 100%;
                    height: 100%;
                }
                div{
                    font-size: 16px;
                    color: #6C727E;
                    margin: 0.1rem 0;
                    span{
                        display: block;
                        color: #A2A2A2;
                    }
                }
            }
            .cerifi-bg{
                background-image: url('../assets/imgs/cerifi-bg.png');
                background-size: 100% 100%;
                padding-bottom: 0.2rem;
            }
        }
}
}
@media screen and (min-width: 1200px){
    .finance-contain{
    .banner-div{
        position: relative;
        img{
            width: 100%;
            height: 3rem;
        }
        .banner-info{
            position: absolute;
            top:40%;
            left: 8%;
            color: #fff;
            div{
                &:first-child{
                font-size: 26px;
                }
                &:nth-child(2){
                font-size: 18px;
                }
                &:nth-child(3){
                font-size: 16px;
                margin-top: 0.1rem;
                }
            }
            }
    }
     .contain{
         padding: 0.2rem 0 0;
              .title{
                font-size: 24px;
                font-weight: bold;
                color: #122C5B;
                text-align: center;
            }
            .title-eng{
                font-size: 16px;
                color: #4676B1;
                text-align: center;
                margin: 0 0 0.2rem;
            }
            .info{
                font-size: 14px;
                color: #6C727E;
                margin: 0.2rem 0;
                text-align: center;
            }
            .item-div{
                width: 50%;
                text-align: center;
                margin: auto;
                img{
                    width: 100%;
                    height: 100%;
                }
                div{
                    font-size: 16px;
                    color: #6C727E;
                    margin: 0.1rem 0;
                    span{
                        display: block;
                        color: #A2A2A2;
                    }
                }
            }
            .cerifi-bg{
                background-image: url('../assets/imgs/cerifi-bg.png');
                background-size: 100% 100%;
                padding-bottom: 0.2rem;
            }
        }
}
}
.finance-contain{
    padding-top: 70px;
    .nav-title{
        position: fixed;
        top:0;
        z-index: 100;
    }
}
</style>