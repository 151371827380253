<template>
    <div class="business">
        <Nav-header class="nav-title"></Nav-header>
        <div class="banner-div">
            <img src="../assets/imgs/new-banner.png" alt="">
            <div class="banner-info">
                <div>NEWS CENTER</div>
                <div>新闻中心</div>
                <div>
                解决资产端和资金端的产品供应<br/>
                资源对接以及流量获取 & 用户激活 & 流量变现等营销问题
                </div>
          </div>
        </div>
        <div class="business-div">
            <div class="tab-list">
                <div :class="{active:activeIndex==index}" v-for="(item,index) in tabList" :key="index" @click="handleTab(index)">{{item}}</div>
            </div>
            <div v-if="activeIndex == 0">
                <div class="title">集团要闻</div>
                <div class="title-eng">GROUP NEWS</div>
                <div class="new-li" v-for="(item,index) in newList" :key="index" @click="jumpDetail(item.id)">
                    <div class="left-img">
                        <img :src="item.coverImageUrl" alt="">
                    </div>
                    <div class="right-new">
                        <div>
                            <div>{{item.publishDate.split('-')[2]}}</div>
                            <div>{{item.publishDate.substring(0,7)}}</div>
                        </div>
                        <div>
                            <div>{{item.title}}</div>
                            <div>{{item.summary}}</div>
                        </div>
                    </div>
                </div>
                 <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pageNum"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
                </el-pagination>
            </div>
            <div v-if="activeIndex == 1">
                <div class="title">行业动态</div>
                <div class="title-eng">INDUSTRY NEWS</div>
                <div class="new-li" v-for="(item,index) in newList" :key="index" @click="jumpDetail(item.id)">
                    <div class="left-img">
                        <img :src="item.coverImageUrl" alt="">
                    </div>
                    <div class="right-new">
                        <div>
                            <div>{{item.publishDate.split('-')[2]}}</div>
                            <div>{{item.publishDate.substring(0,7)}}</div>
                        </div>
                        <div>
                            <div>{{item.title}}</div>
                            <div>{{item.summary}}</div>
                        </div>
                    </div>
                </div>
                 <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pageNum"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
                </el-pagination>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>
<script>
    import NavHeader from '../components/nav'
    import Footer from '../components/footer'
    export default {
        data(){
            return{
                tabList:['集团要闻','行业动态'],
                activeIndex:0,
                newList:[],
                total:0,
                pageSize:10,
                pageNum:1,
                newsType:1
            }
        },
        components:{
            NavHeader,
            Footer
        },
        mounted() {
            this.activeIndex = this.$route.query.activeIndex||0
            this.newsType = parseInt(this.activeIndex)+1
            this.initData()
        },
        watch:{
            $route(to,from){
                this.$router.go(0)
            }
        },
        methods:{
            initData(){
                this.$http.get('/news/summary/list',{
                params:{
                    pageSize:this.pageSize,
                    pageNum:this.pageNum,
                    newsType:this.newsType
                }
                }).then(res=>{
                    if(res.data.code == 200){
                        this.newList = res.data.data.rows
                        this.total =  res.data.data.total
                    }else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            handleTab(index){
                this.activeIndex=index
                this.newsType = parseInt(this.activeIndex)+1
                this.pageSize=10,
                this.pageNum=1,
                this.initData()
            },
            jumpDetail(id){
                this.$router.push({
                    path:'/newDetail',
                    query:{id}
                })
            },
            handleSizeChange(val){ //每页多少条
                this.pageSize = val
                this.initData()
            },
            handleCurrentChange(val){ //当前页
                this.pageNum = val
                this.initData()
            }
        }
    }
</script>
<style lang="less" scoped>
.el-pagination{
    text-align: center;
    margin: 0.2rem;
}
@media screen and (max-width: 768px){
     .business{
         .banner-div{
            position: relative;
            img{
                width: 100%;
                height: 5rem;
            }
            .banner-info{
                position: absolute;
                top:20%;
                left: 8%;
                color: #fff;
                div{
                    &:first-child{
                    font-size: 18px;
                    }
                    &:nth-child(2){
                    font-size: 14px;
                    }
                    &:nth-child(3){
                    font-size: 12px;
                    margin-top: 0.1rem;
                    }
                }
            }
        }
        .business-div{
            .tab-list{
                display: flex;
                div{
                    flex:1;
                    text-align: center;
                    color: #6C727E;
                    font-weight: bold;
                    font-size: 16px;
                    padding: 0.5rem 0;
                    border-bottom: 1px solid #D2E0EB;
                    cursor: pointer;
                }
                .active{
                    color: #3D56A3;
                }
            }
            .title{
                font-size: 18px;
                font-weight: bold;
                color: #122C5B;
                text-align: center;
                margin-top: 0.2rem;
            }
            .title-eng{
                font-size: 14px;
                color: #4676B1;
                text-align: center;
                margin: 0 0 0.2rem;
            }
             .new-li{
                margin: 0.3rem 2%;
                display: flex;
                align-items:flex-end;
                .left-img{
                    width: 20%;
                    img{
                        width: 100%;
                    }
                }
                .right-new{
                    width: 80%;
                    padding: 0.2rem 0;
                    border-bottom: 1px solid #E6F0FF;
                    margin-left: 0.2rem;
                    display: flex;
                    align-items: center;
                    >div{
                        &:first-child{
                            margin-right: 0.3rem;
                            white-space: nowrap;
                            >div{
                                text-align: center;
                                color: #989CA2;
                                font-size: 14px;
                                &:first-child{
                                    font-size: 20px;
                                    color: #989CA2;
                                }
                            }
                        }
                        &:nth-child(2){
                            >div{
                                &:first-child{
                                    font-size: 14px;
                                    font-weight: bold;
                                    color: #546DB9;
                                    padding: 0.1rem 0;
                                    text-overflow: -o-ellipsis-lastline;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 1;
                                    line-clamp: 1;
                                    -webkit-box-orient: vertical;
                                }
                                &:nth-child(2){
                                    font-size: 12px;
                                    color: #989CA2;
                                    text-overflow: -o-ellipsis-lastline;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 2;
                                    line-clamp: 2;
                                    -webkit-box-orient: vertical;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
@media screen and (min-width: 768px){
     .business{
        .banner-div{
            position: relative;
            img{
                width: 100%;
                height: 5rem;
            }
            .banner-info{
                position: absolute;
                top:30%;
                left: 8%;
                color: #fff;
                div{
                    &:first-child{
                    font-size: 26px;
                    }
                    &:nth-child(2){
                    font-size: 18px;
                    }
                    &:nth-child(3){
                    font-size: 16px;
                    margin-top: 0.1rem;
                    }
                }
            }
        }
        .business-div{
            .tab-list{
                display: flex;
                div{
                    flex:1;
                    text-align: center;
                    color: #6C727E;
                    font-weight: bold;
                    font-size: 18px;
                    padding: 0.2rem 0;
                    border-bottom: 1px solid #D2E0EB;
                    cursor: pointer;
                }
                .active{
                    color: #3D56A3;
                }
            }
            .title{
                font-size: 22px;
                font-weight: bold;
                color: #122C5B;
                text-align: center;
                margin-top: 0.2rem;
            }
            .title-eng{
                font-size: 16px;
                color: #4676B1;
                text-align: center;
                margin: 0 0 0.2rem;
            }
            .new-li{
                margin: 0.2rem 8%;
                display: flex;
                align-items:flex-end;
                .left-img{
                    width: 20%;
                     img{
                        width: 100%;
                    }
                }
                .right-new{
                    width: 80%;
                    border-bottom: 1px solid #E6F0FF;
                    margin-left: 0.2rem;
                    display: flex;
                    align-items: center;
                    height: 2rem;
                    >div{
                        &:first-child{
                            margin-right: 0.3rem;
                            white-space: nowrap;
                            >div{
                                text-align: center;
                                color: #989CA2;
                                font-size: 16px;
                                &:first-child{
                                    font-size: 35px;
                                    color: #989CA2;
                                }
                            }
                        }
                        &:nth-child(2){
                            >div{
                                &:first-child{
                                    font-size: 16px;
                                    font-weight: bold;
                                    color: #546DB9;
                                    padding: 0.1rem 0;
                                }
                                &:nth-child(2){
                                    font-size: 16px;
                                    color: #989CA2;
                                    text-overflow: -o-ellipsis-lastline;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 2;
                                    line-clamp: 2;
                                    -webkit-box-orient: vertical;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
@media screen and (min-width: 1200px){
    .business{
         .banner-div{
            position: relative;
            img{
                width: 100%;
                height: 3rem;
            }
            .banner-info{
                position: absolute;
                top:40%;
                left: 8%;
                color: #fff;
                div{
                    &:first-child{
                    font-size: 26px;
                    }
                    &:nth-child(2){
                    font-size: 18px;
                    }
                    &:nth-child(3){
                    font-size: 16px;
                    margin-top: 0.1rem;
                    }
                }
            }
        }
        .business-div{
            .tab-list{
                display: flex;
                div{
                    flex:1;
                    text-align: center;
                    color: #6C727E;
                    font-weight: bold;
                    font-size: 18px;
                    padding: 0.2rem 0;
                    border-bottom: 1px solid #D2E0EB;
                    cursor: pointer;
                }
                .active{
                    color: #3D56A3;
                }
            }
            .title{
                font-size: 24px;
                font-weight: bold;
                color: #122C5B;
                text-align: center;
                margin-top: 0.2rem;
            }
            .title-eng{
                font-size: 16px;
                color: #4676B1;
                text-align: center;
                margin: 0 0 0.2rem;
            }
            .new-li{
                margin: 0.2rem 10%;
                display: flex;
                align-items:flex-end;
                .left-img{
                    width: 11%;
                    img{
                        width: 100%;
                    }
                }
                .right-new{
                    width: 80%;
                    border-bottom: 1px solid #E6F0FF;
                    margin-left: 0.2rem;
                    display: flex;
                    align-items: center;
                    height: 1rem;
                    >div{
                        &:first-child{
                            margin-right: 0.2rem;
                            white-space: nowrap;
                            >div{
                                text-align: center;
                                color: #989CA2;
                                font-size: 16px;
                                &:first-child{
                                    font-size: 50px;
                                    color: #989CA2;
                                }
                            }
                        }
                        &:nth-child(2){
                            >div{
                                &:first-child{
                                    font-size: 16px;
                                    font-weight: bold;
                                    color: #546DB9;
                                    padding: 0.1rem 0;
                                }
                                &:nth-child(2){
                                    font-size: 16px;
                                    color: #989CA2;
                                    text-overflow: -o-ellipsis-lastline;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 2;
                                    line-clamp: 2;
                                    -webkit-box-orient: vertical;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.business{
    padding-top: 70px;
    .nav-title{
        position: fixed;
        top:0;
        z-index: 100;
    }
}
</style>