<template>
    <div class="footer">
        <div class="row">
            <div class="col-xs-12 col-sm-3 footer-item">
                <div>公司地址</div>
                <div>上海市浦东新区陆家嘴环路166号未来资产大厦</div>
                <div>北京市东城区东直门外大街46号天恒大厦</div>
                <div>南京市建邺区白龙江东街9号金鱼嘴基金大厦</div>
            </div>
            <div class="col-xs-6 col-sm-3 footer-item">
                <div>官方热线</div>
                <div>服务时间：</div>
                <div>08:00-21:00</div>
                <div>010-64068617</div>
            </div>
            <div class="col-xs-6 col-sm-3 footer-item">
                <div>集团相关平台</div>
                <div><a href="http://www.fdsure.com">方德保险代理官网</a></div>
                <div><a href="http://www.fundsure.cn">简基金官网</a></div>
            </div>
            <div class="col-xs-12 col-sm-3 footer-item">
                <div>集团相关公众号</div>
                    <div class="footer-qrcode">
                        <div>
                            <div><img src="../assets/imgs/qrcode1.png" alt=""></div>
                            <div>方德保代</div>
                        </div>
                        <div>
                            <div><img src="../assets/imgs/qrcode2.png" alt=""></div>
                            <div>简基金</div>
                        </div>
                            <div>
                                <div><img src="../assets/imgs/qrcode3.png" alt=""></div>
                                <div>财友趣</div>
                            </div>
                        </div>
            </div>
        </div>
        <div class="copyright" v-if="screenWidth<768">
            Copyright© 2021新方德fdsuretech.com版权所有<br/> <a style="color:#C3C3C3;" href="https://beian.miit.gov.cn">粤ICP备2021084799号</a>
        </div>
        <div class="copyright" v-else> Copyright© 2021新方德fdsuretech.com版权所有  <a style="color:#C3C3C3;" href="https://beian.miit.gov.cn">粤ICP备2021084799号</a></div>
    </div>
</template>
<script>
    export default{
        data(){
            return{
                screenWidth:0
            }
        },
        mounted() {
            this.$nextTick(()=>{
                this.screenWidth = window.innerWidth
            })
        },
    }
</script>
<style lang="less" scoped>
@media screen and (max-width: 768px){
    .footer{
        width: 100%;
        background: #6D7B8E;
        padding:0.5rem 0 0;
        .footer-item{
            margin: 0.3rem 0;
            padding: 0 1.5rem;
            >div{
                color: #C3C3C3;
                font-size: 12px;
                margin-bottom: 0.05rem;
                &:first-child{
                    color: #86C9EF;
                    font-size: 14px;
                    font-weight: bold;
                    margin-bottom: 0.1rem;
                }
            }
            a{
                color: #C3C3C3;
            }
        }
        .footer-qrcode{
            display: flex;
            justify-content: space-between;
            text-align: center;
            img{
                margin: 0.2rem 0;
            }
        }
        .copyright{
            color: #C3C3C3;
            font-size: 14px;
            text-align: center;
            padding: 0.2rem 0;
            border-top:1px solid #64717B;
        }
    }
}
@media screen and (min-width: 768px){
    .footer{
        width: 100%;
        background: #6D7B8E;
        padding:0.2rem 0 0;
        .footer-item{
            padding: 0 0.5rem;
            >div{
                color: #C3C3C3;
                font-size: 14px;
                margin-bottom: 0.05rem;
                &:first-child{
                    color: #86C9EF;
                    font-size: 16px;
                    font-weight: bold;
                    margin-bottom: 0.1rem;
                }
            }
             a{
                color: #C3C3C3;
            }
        }
        .footer-qrcode{
            display: flex;
            text-align: center;
            div{
                margin-right: 0.1rem;
            }
            img{
                width: 0.5rem;
                height: 0.5rem;
                margin-bottom: 0.05rem;
            }
        }
        .copyright{
            color: #C3C3C3;
            font-size: 14px;
            text-align: center;
            padding: 0.1rem 0;
            border-top:1px solid #64717B;
        }
    }
}
@media screen and (min-width: 1024px){
    .footer{
        width: 100%;
        background: #6D7B8E;
        padding:0.2rem 0 0;
        .footer-item{
            padding: 0 0.5rem;
            >div{
                color: #C3C3C3;
                font-size: 14px;
                margin-bottom: 0.05rem;
                &:first-child{
                    color: #86C9EF;
                    font-size: 16px;
                    font-weight: bold;
                    margin-bottom: 0.1rem;
                }
            }
             a{
                color: #C3C3C3;
            }
        }
        .footer-qrcode{
            display: flex;
            text-align: center;
            div{
                margin-right: 0.1rem;
            }
            img{
                width: 0.5rem;
                height: 0.5rem;
                margin-bottom: 0.05rem;
            }
        }
        .copyright{
            color: #C3C3C3;
            font-size: 14px;
            text-align: center;
            padding: 0.1rem 0;
            border-top:1px solid #64717B;
        }
    }
}
@media screen and (min-width: 1200px){
    .footer{
        width: 100%;
        background: #6D7B8E;
        padding:0.2rem 0 0;
        .footer-item{
            padding: 0 0.5rem;
            >div{
                color: #C3C3C3;
                font-size: 14px;
                margin-bottom: 0.05rem;
                &:first-child{
                    color: #86C9EF;
                    font-size: 16px;
                    font-weight: bold;
                    margin-bottom: 0.1rem;
                }
            }
             a{
                color: #C3C3C3;
            }
        }
        .footer-qrcode{
            display: flex;
            text-align: center;
            div{
                margin-right: 0.1rem;
            }
            img{
                width: 0.5rem;
                height: 0.5rem;
                margin-bottom: 0.05rem;
            }
        }
        .copyright{
            color: #C3C3C3;
            font-size: 14px;
            text-align: center;
            padding: 0.1rem 0;
            border-top:1px solid #64717B;
        }
    }
}

</style>
