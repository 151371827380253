<template>
    <div class="nav-div">
        <nav class="navbar navbar-default" role="navigation" :class="{navbarHome:urlPath=='home'}">
            <div class="container-fluid"> 
            <div class="navbar-header">
                <button type="button" class="navbar-toggle" data-toggle="collapse"
                        data-target="#example-navbar-collapse">
                    <span class="sr-only">切换导航</span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                </button>
                <div class="logo-header">
                    <img v-if="urlPath=='home'&&screenWidth>=768" src="../assets/imgs/logo.png" alt="">
                    <img v-else src="../assets/imgs/logo-black.png" alt="">
                </div>
            </div>
            <div class="collapse navbar-collapse nav-list" id="example-navbar-collapse">
                <ul class="nav navbar-nav">
                    <li :class="{active:urlPath=='home' && !activeType}" @click="handleNav('')">
                        <router-link to="/home">网站首页</router-link>
                        <div></div>
                    </li>
                    <li class="dropdown" :class="{active:urlPath=='aboutUs'|| activeType == '关于我们'}">
                        <a class="dropdown-toggle" data-toggle="dropdown" @click="handleNav('关于我们')">关于我们</a>
                        <div></div>
                        <ul class="dropdown-menu" >
                            <li><router-link :to="{path:'/aboutUs',query:{activeIndex:0}}">集团介绍</router-link></li>
                            <li><router-link :to="{path:'/aboutUs',query:{activeIndex:1}}">企业文化</router-link></li>
                            <li><router-link :to="{path:'/aboutUs',query:{activeIndex:2}}">合作伙伴</router-link></li>
                        </ul>
                    </li>
                    <li :class="{active:urlPath=='financialQualification' && !activeType}" @click="handleNav('')">
                        <router-link to="/financialQualification">金融资质</router-link>
                        <div></div>
                    </li>
                    <li class="dropdown" :class="{active:urlPath=='brand'|| activeType == '旗下品牌'}">
                        <a class="dropdown-toggle" data-toggle="dropdown" @click="handleNav('旗下品牌')">旗下品牌</a>
                        <div></div>
                        <ul class="dropdown-menu" >
                            <li><router-link :to="{path:'/brand',query:{activeIndex:0}}">简基金</router-link></li>
                            <li><router-link :to="{path:'/brand',query:{activeIndex:1}}">方德保代</router-link></li>
                        </ul>
                    </li>
                    <li v-if="urlPath=='home'&&screenWidth>=768"><router-link to="/"><img src="../assets/imgs/logo.png" alt=""></router-link></li>
                    <li v-if="urlPath!='home'&&screenWidth>=768"><router-link to="/"><img src="../assets/imgs/logo-black.png" alt=""></router-link></li>
                    <li class="dropdown" :class="{active:urlPath=='businessMode' || activeType == '业务模式'}">
                        <a class="dropdown-toggle" data-toggle="dropdown" @click="handleNav('业务模式')">业务模式</a>
                        <div></div>
                        <ul class="dropdown-menu" >
                            <li><router-link :to="{path:'/businessMode',query:{activeIndex:0}}">全流程运营管理</router-link></li>
                            <li><router-link :to="{path:'/businessMode',query:{activeIndex:1}}">全维度评价体系</router-link></li>
                            <li><router-link :to="{path:'/businessMode',query:{activeIndex:2}}">全场景智能投顾</router-link></li>
                        </ul>
                    </li>
                    <li class="dropdown" :class="{active:urlPath=='news'|| urlPath=='newDetail' || activeType == '新闻中心'}">
                        <a class="dropdown-toggle" data-toggle="dropdown" @click="handleNav('新闻中心')">新闻中心</a>
                        <div></div>
                        <ul class="dropdown-menu" >
                            <li><router-link :to="{path:'/news',query:{activeIndex:0}}">集团要闻</router-link></li>
                            <li><router-link :to="{path:'/news',query:{activeIndex:1}}">行业动态</router-link></li>
                        </ul>
                    </li>
                    <li :class="{active:urlPath=='riskControl' && !activeType}" @click="handleNav('')">
                        <router-link to="/riskControl">风险控制</router-link>
                        <div></div>
                    </li>
                    <li class="dropdown" :class="{active:urlPath=='joinUs' || activeType == '加入我们'}">
                        <a class="dropdown-toggle" data-toggle="dropdown" @click="handleNav('加入我们')">加入我们</a>
                        <div></div>
                        <ul class="dropdown-menu" >
                            <li><router-link :to="{path:'/joinUs',query:{activeIndex:0}}">加入我们</router-link></li>
                            <li><router-link :to="{path:'/joinUs',query:{activeIndex:1}}">校园招聘</router-link></li>
                        </ul>
                    </li>
                </ul>
            </div>
            </div>
        </nav>
    </div>
</template>

<script>
  import 'bootstrap/dist/css/bootstrap.css'
  import 'bootstrap/dist/js/bootstrap.js'

    export default {
        data(){
            return{
                urlPath:'',
                screenWidth:0,
                activeType:''
            }
        },
        mounted(){
            this.urlPath = this.$route.path.substring(1)
            this.screenWidth = window.innerWidth
        },
        methods:{
            handleNav(type){
                this.activeType = type
            },
            // mouseOver($event) {
            //     $event.currentTarget.className.indexOf("active")>-1 ? $event.currentTarget.className = 'active open' :$event.currentTarget.className = 'open'
            // },
            //     // 移出
            // mouseLeave($event) {
            //     $event.currentTarget.className.indexOf("active")>-1 ? $event.currentTarget.className = 'active':$event.currentTarget.className = ''
            // }
        }
    }
</script>

<style lang="less" scoped>
@media screen and (max-width: 768px){
    .logo-header{
        display: block;
    }
    .nav-div{
        width: 100%;
        .navbar{
            background-color: rgba(#FCFCFC,0.8);
            height: 70px;
            margin-bottom: 0 !important;
            border:none;
            .navbar-header{
                height: 70px;
                line-height: 70px;
                text-align: center;
                img{
                    width: 6rem;
                    height: 1.2rem;
                }
            }
            .navbar-toggle{
                margin-top: 18px;
            }
            .navbar-collapse{
                position: absolute;
                z-index: 1;
                width: 100%;
                background-color: #F6F6F6;
                img{
                    width: 195px;
                    height: 30px;
                }
                ul{
                    width: 100%;
                    padding-left: 0.5rem;
                    li{
                        background-color: #F6F6F6;
                        border-bottom: 1px solid #D1D1D1;
                        width: 100%;
                        a{
                            color: #424242;
                        }
                        
                    }
                    .active>a{
                        color: #C3E9FF;
                        background-color: transparent;
                    }
                }
            }
        }
        .navbarHome{
            background-color: rgba(#FCFCFC,0.8);
            height: 70px;
            margin-bottom: 0 !important;
            border:none;
            .navbar-header{
                height: 70px;
                line-height: 70px;
                text-align: center;
                img{
                    width: 6rem;
                    height: 1.2rem;
                }
            }
            .navbar-toggle{
                margin-top: 18px;
            }
            .navbar-collapse{
                background-color: #F6F6F6;
              img{
                    width: 195px;
                    height: 30px;
                }
                ul{
                    width: 100%;
                    padding-left: 0.5rem;
                    li{
                        background-color: #F6F6F6;
                        border-bottom: 1px solid #D1D1D1;
                        width: 100%;
                        a{
                            color: #424242;
                        }
                        
                    }
                    .active>a{
                        color: #C3E9FF;
                        background-color: transparent;
                    }
                }
            }
        }
    }
    .dropdown-menu{
        li{
            border-bottom:none !important;
        }
    }
}
@media screen and (min-width: 768px){
    .nav > li > a{
        padding: 10px 0;
    }
    .navbar-default .navbar-nav > .open > a[data-v-0276edc3], .navbar-default .navbar-nav > .open > a[data-v-0276edc3]:focus, .navbar-default .navbar-nav > .open > a[data-v-0276edc3]:hover{
        background-color:transparent;
    }
    a:hover,a:active{
        font-weight: bold;
    }
    .dropdown-menu{
        height: 60px;
        line-height: 60px;
        margin: 0;
        padding: 0;
        li{
            display: inline-block;
            width: 15% !important;
            a:hover{
                background-color:transparent;
            }
        }
        
        background-color: rgba(#fff,0.2);
        color: #212C67;
        width: 100vw !important;
    }
    .nav>li{
        position: static;
    }
    .logo-header{
        display: none;
    }
    .nav-div{
        width: 100%;
        .navbar{
            background-color: rgba(#FCFCFC,0.8);
            height: 70px;
            margin-bottom: 0 !important;
            border:none;
            .navbar-header{
                height: 70px;
                line-height: 70px;
                text-align: center;
                img{
                    width: 2.52rem;
                    height: 0.55rem;
                }
            }
            .navbar-toggle{
                margin-top: 0.3rem;
            }
            .navbar-collapse{
                img{
                    width: 195px;
                    height: 30px;
                    margin-top: -5px;
                }
                >ul{
                    width: 100%;
                    height: 70px;
                    line-height: 70px;
                    li{
                        width: 9%;
                        height: 70px;
                        padding-top: 12px;
                        text-align: center;
                        &:nth-child(5){
                            width: 28%;
                        }
                        a{
                            color: #212C67;
                            white-space: nowrap;
                        }
                        div{
                            background: transparent;
                            width: 57px;
                            height: 2px;
                            margin: -5px auto 0;
                        }
                    }
                    .active>a{
                        color: #212C67;
                        font-weight: bold;
                        background-color: transparent;
                    }
                    .active>div{
                        background: linear-gradient(90deg, #6EC7F1 0%, #206B8B 100%);
                        width: 57px;
                        height: 2px;
                        margin: -5px auto 0;
                    }
                }
            }
        }
        .navbarHome{
            background-color: rgba(#000,0.22);
            height: 70px;
            margin-bottom: 0 !important;
            border:none;
            .navbar-header{
                height: 70px;
                line-height: 70px;
                text-align: center;
                img{
                    width: 2.52rem;
                    height: 0.55rem;
                }
            }
            .navbar-toggle{
                margin-top: 0.3rem;
            }
            .navbar-collapse{
                img{
                    width: 195px;
                    height: 30px;
                    margin-top: -5px;
                }
                >ul{
                     width: 100%;
                    height: 70px;
                    line-height: 70px;
                    li{
                        width: 9%;
                        height: 70px;
                        padding-top: 12px;
                        text-align: center;
                        &:nth-child(5){
                            width: 28%;
                        }
                        a{
                            color: #fff;
                        }
                        
                    }
                    .active>a{
                        color: #C3E9FF;
                        background-color: transparent;
                    }
                }
            }
        }
    }
}
@media screen and (min-width: 1200px){
    .navbar-default .navbar-nav > .open > a[data-v-0276edc3], .navbar-default .navbar-nav > .open > a[data-v-0276edc3]:focus, .navbar-default .navbar-nav > .open > a[data-v-0276edc3]:hover{
        background-color:transparent;
    }
    a:hover,a:active{
        font-weight: bold;
    }

    .dropdown-menu{
        li{
            display: inline-block;
            a:hover{
                background-color:transparent;
            }
        }
        
        background-color: rgba(#fff,0.2);
        color: #212C67;
        width: 100vw !important;
    }
    .nav>li{
        position: static;
    }
    .logo-header{
        display: none;
    }
    .nav-div{
        width: 100%;
        .navbar{
            background-color: rgba(#FCFCFC,0.8);
            height: 70px;
            margin-bottom: 0 !important;
            border:none;
            .navbar-header{
                height: 70px;
                line-height: 70px;
                text-align: center;
                img{
                    width: 2.52rem;
                    height: 0.55rem;
                }
            }
            .navbar-toggle{
                margin-top: 0.3rem;
            }
            .navbar-collapse{
                img{
                    width: 195px;
                    height: 30px;
                    margin-top: -5px;
                }
                >ul{
                    width: 100%;
                    height: 70px;
                    line-height: 70px;
                    li{
                        width: 10%;
                        height: 70px;
                        padding-top: 12px;
                        text-align: center;
                         &:nth-child(5){
                            width: 20%;
                        }
                        a{
                            color: #3e4364;
                        }
                        div{
                            background: transparent;
                            width: 57px;
                            height: 2px;
                            margin: -5px auto 0;
                        }
                    }
                    .active>a{
                        color: #212C67;
                        font-weight: bold;
                        background-color: transparent;
                    }
                    .active>div{
                        background: linear-gradient(90deg, #6EC7F1 0%, #206B8B 100%);
                        width: 57px;
                        height: 2px;
                        margin: -5px auto 0;
                    }
                }
            }
        }
        .navbarHome{
            background-color: rgba(#000,0.22);
            height: 70px;
            margin-bottom: 0 !important;
            border:none;
            .navbar-header{
                height: 70px;
                line-height: 70px;
                text-align: center;
                img{
                    width: 2.52rem;
                    height: 0.55rem;
                }
            }
            .navbar-toggle{
                margin-top: 0.3rem;
            }
            .navbar-collapse{
                img{
                    width: 195px;
                    height: 30px;
                    margin-top: -5px;
                }
                >ul{
                     width: 100%;
                    height: 70px;
                    line-height: 70px;
                    li{
                        text-align: center;
                        width: 10%;
                        height: 70px;
                        padding-top: 12px;
                        text-align: center;
                        &:nth-child(5){
                            width: 20%;
                        }
                        a{
                            color: #fff;
                        }
                        
                    }
                    .active>a{
                        color: #C3E9FF;
                        background-color: transparent;
                    }
                }
            }
        }
    }
}


</style>