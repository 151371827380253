<template>
    <div class="brand">
        <Nav-header class="nav-title"></Nav-header>
        <div class="banner-div">
            <img src="../assets/imgs/band-banner.png" alt="">
            <div class="banner-info">
                <div>BRANDS</div>
                <div>旗下品牌</div>
                <div>
                简基金——人人可以发策略，打造“做自己的金融家”<br/>
                方德保险——全国分支机构覆盖 19+省<br/>
                1000+保险代理人<br/>
                共 30+分支机构<br/>
                </div>
          </div>
        </div>
        <div class="brand-content">
            <div class="tab-list">
                <div :class="{active:activeIndex==index}" v-for="(item,index) in tabList" :key="index" @click="handleTab(index)">{{item}}</div>
            </div>
            <div class="fund" v-if="activeIndex == 0">
                <div class="title">简基金</div>
                <div class="title-eng">BRANDS</div>
                <div class="info">
                    我们利用自有的公募基金APP<span>“简基金”</span>，让加盟伙伴可以在“简基金”上发行自己的投资策略。通过参与打榜等运营活动，用专业性吸引用户成为自己的客户群，助力加盟伙伴成为金融<span>KOL</span>，增加自己的<span>获客渠道</span>。
                </div>
                <div class="info">简基金平台提供<span>自主定制产品组合服务</span>。简基金平台引入财经界资深人士进行专业的公募基金策略定制，投资者可实时查看<span>大V的策略</span>分享，进行互动和跟投。对于有经验有想法的投资者，也可在简基金平台自主发布策略，吸引粉丝跟投。</div>
                <div class="upload">
                    <div class="left-btn">
                        <img src="../assets/imgs/ios.png" alt="">
                        <div @click="uploadVisible = true">IOS下载</div>
                    </div>
                    <div class="right-btn">
                        <img src="../assets/imgs/android.png" alt="">
                        <div @click="uploadVisible = true">Android下载</div>
                    </div>
                </div>
                <div class="brand-info">
                    <img src="../assets/imgs/brand-info.png" alt="">
                </div>
            </div>
            <div class="fund" v-if="activeIndex == 1">
                <div class="title">方德保代</div>
                <div class="title-eng">BRANDS</div>
                <div class="brand-nums">全国分支机构覆盖 <span>19+</span>省   <span>1000+</span>保险代理人   共 <span>30+</span>分支机构</div>
                <div class="fund-detail">方德保险代理有限公司<span>专注保险服务15年</span>，为10万客户提供更优质的保障服务，整合超过<span>五十家</span>产寿险公司的<span>上千款产品</span>，愿景是成为<span>符合华人的最佳保险</span>产品与<span>独立保代服务</span>平台。方德保险代理有限公司是全国仅有的五家持有<span>【基金销售牌照】</span>的保险代理公司之一。</div>
                <div class="detail-title">主营业务</div>
                <div class="detail-item row">
                    <div class="col-xs-6 col-ms-3">
                        <img src="../assets/imgs/brand11.png" alt="">
                        <div>寿险业务</div>
                    </div>
                    <div class="col-xs-6 col-ms-3">
                        <img src="../assets/imgs/brand12.png" alt="">
                        <div>财险业务</div>
                    </div>
                    <div class="col-xs-6 col-ms-3">
                        <img src="../assets/imgs/brand13.png" alt="">
                        <div>团队客户</div>
                    </div>
                    <div class="col-xs-6 col-ms-3">
                        <img src="../assets/imgs/brand14.png" alt="">
                        <div>个人客户</div>
                    </div>
                </div>
                <div class="cerifi-flex" v-if="screenWidth>=768">
                    <div class="left">
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <div class="center">
                        <div>
                            <div>2004年</div>
                            <div>获得中国保监会颁发的全国保险代理牌照、互联网保险销售资质，成为全国性保险代理经营机构。</div>
                        </div>
                        <div>
                            <div>2016年</div>
                            <div>获得中国证监会颁发的基金销售资质。方德保代可以代销公募基金、私募基金、信托、资管产品及符合监管要求的“受国务院金融监管部门监管的金融理财产品”</div>
                        </div>
                        <div>
                            <div>2019年</div>
                            <div>经中国人民银行核准为证券业法人金融机构和保险业法人金融机构。</div>
                        </div>
                    </div>
                    <div class="right"><img src="../assets/imgs/cerifi4.png" alt=""></div>
                </div>
                <div v-else class="app-cerifi">
                     <div class="center">
                        <div>
                            <div>2004年</div>
                            <div>获得中国保监会颁发的全国保险代理牌照、互联网保险销售资质，成为全国性保险代理经营机构。</div>
                        </div>
                        <div>
                            <div>2016年</div>
                            <div>获得中国证监会颁发的基金销售资质。方德保代可以代销公募基金、私募基金、信托、资管产品及符合监管要求的“受国务院金融监管部门监管的金融理财产品”</div>
                        </div>
                        <div>
                            <div>2019年</div>
                            <div>经中国人民银行核准为证券业法人金融机构和保险业法人金融机构。</div>
                        </div>
                    </div>
                    <div class="right"><img src="../assets/imgs/cerifi4.png" alt=""></div>
                </div>
                <div class="detail-bg">
                    <div>愿景</div>
                    <div>成为符合华人的最佳保险产品与独立保代服务平台</div>
                    <div>使命</div>
                    <div>赋能有梦想的“保险代理人”，升级为家庭资产配置理财专家</div>
                </div>
                <div v-if="screenWidth>=768">
                    <div class="detail-List">
                        <div>
                            <img src="../assets/imgs/detail02.png" alt="">
                        </div>
                        <div>
                            <div>省 时</div>
                            <div>使您无须大量查询保险知识，也无须咨询多家保险公司代理人，帮您客观分析自身需求，全面了解市场信息。</div>
                        </div>
                        <div>
                            <div>省 力</div>
                            <div>协助您办理投保、保全、理赔等各项手续，提供长期专业市场信息。</div>
                        </div>
                         <div>
                            <img src="../assets/imgs/detail03.png" alt="">
                        </div>
                         <div>
                            <img src="../assets/imgs/detail04.png" alt="">
                        </div>
                        <div>
                            <div>省 心</div>
                            <div>核保时，提供专业建议，帮助您获得最优结论；理赔时，提供专业协助，帮助您争取合法权益。</div>
                        </div>
                        <div>
                            <div>省 钱</div>
                            <div>为您精选众多保险公司的优势产品并加以组合，能帮助您平均节省10%-30%的保费支出。</div>
                        </div>
                        <div>
                            <img src="../assets/imgs/detail05.png" alt="">
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div class="detail-List-app">
                        <div>
                            <img src="../assets/imgs/detail02.png" alt="">
                        </div>
                        <div>
                            <div>省 时</div>
                            <div>使您无须大量查询保险知识，也无须咨询多家保险公司代理人，帮您客观分析自身需求，全面了解市场信息。</div>
                        </div>
                        <div>
                            <img src="../assets/imgs/detail03.png" alt="">
                        </div>
                        <div>
                            <div>省 力</div>
                            <div>协助您办理投保、保全、理赔等各项手续，提供长期专业市场信息。</div>
                        </div>
                         <div>
                            <img src="../assets/imgs/detail04.png" alt="">
                        </div>
                        <div>
                            <div>省 心</div>
                            <div>核保时，提供专业建议，帮助您获得最优结论；理赔时，提供专业协助，帮助您争取合法权益。</div>
                        </div>
                        <div>
                            <img src="../assets/imgs/detail05.png" alt="">
                        </div>
                        <div>
                            <div>省 钱</div>
                            <div>为您精选众多保险公司的优势产品并加以组合，能帮助您平均节省10%-30%的保费支出。</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer></Footer>
        <div v-if="uploadVisible" class="toast-qrcode" @click="uploadVisible = false">
             <img src="../assets/imgs/qrcode.png" alt="">
         </div>
    </div>
</template>
<script>
    import NavHeader from '../components/nav'
    import Footer from '../components/footer'
    export default {
        data(){
            return{
                tabList:['简基金','方德保代'],
                activeIndex:0,
                screenWidth:0,
                uploadVisible:false
            }
        },
        components:{
            NavHeader,
            Footer
        },
        mounted() {
            this.activeIndex = this.$route.query.activeIndex||0
            this.$nextTick(()=>{
                this.screenWidth = window.innerWidth
            })
        },
        watch:{
            $route(to,from){
                this.$router.go(0)
            }
        },
        methods:{
            handleTab(index){
                this.activeIndex=index
            },
        }
    }
</script>
<style lang="less" scoped>
@media screen and (max-width: 768px){
    .toast-qrcode{
        position: fixed;
        top:0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(#212C67,0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        img{
            width: 4rem;
            height: 4rem;
        }
    }
    .brand{
        .banner-div{
            position: relative;
            img{
                width: 100%;
                height: 5rem;
            }
            .banner-info{
        position: absolute;
        top:20%;
        left: 8%;
        color: #fff;
        div{
            &:first-child{
            font-size: 18px;
            }
            &:nth-child(2){
            font-size: 14px;
            }
            &:nth-child(3){
            font-size: 12px;
            margin-top: 0.1rem;
            }
        }
        }
        }
        .brand-content{
            .tab-list{
                display: flex;
                div{
                    flex:1;
                    text-align: center;
                    color: #6C727E;
                    font-weight: bold;
                    font-size: 16px;
                    padding: 0.5rem 0;
                    border-bottom: 1px solid #D2E0EB;
                    cursor: pointer;
                }
                .active{
                    color: #3D56A3;
                }
                }
            .title{
                font-size: 20px;
                font-weight: bold;
                color: #122C5B;
                text-align: center;
                margin-top: 0.2rem;
            }
            .title-eng{
                font-size: 14px;
                color: #4676B1;
                text-align: center;
                margin: 0 0 0.2rem;
            }
            .info{
                margin: 0.2rem 8%;
                            font-size: 12px;
                            color: #6C727E;
                            span{
                                font-size: 14px;
                                color: #6A98D1;
                            }
                }
            .upload{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        text-align: center;
                        width: 70%;
                        margin: auto;
                        .left-btn,.right-btn{
                            margin: 0.5rem;
                            img{
                                width: 3rem;
                                height: 3rem;
                                margin-bottom: 0.2rem;
                            }
                            div{
                                padding:0.2rem 0.3rem;
                                background: #546DB9;
                                box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
                                border-radius: 30px;
                                color: #fff;
                                font-size: 12px;
                            }
                        }
                    }
            .brand-info{
                width: 96%;
                margin: 0.2rem 2%;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
            .brand-nums{
                font-size: 12px;
                color: #6C727E;
                text-align: center;
                    span{
                        font-size: 14px;
                        color: #6A98D1;
                        font-weight: bold;
                    }
            }
              .fund-detail{
                margin: 0.3rem 8%;
                font-size: 12px;
                color: #6C727E;
                    span{
                        font-size: 14px;
                        color: #6A98D1;
                    }
            }
            .detail-title{
                font-size: 20px;
                font-weight: bold;
                color: #122C5B;
                text-align: center;
            }
            .detail-item{
                // display: flex;
                // text-align: center;
                margin: 0.2rem;
                // justify-content: center;
                >div{
                    margin: 0.3rem 0;
                   img{
                    width: 100%;
                    }
                    div{
                        background-color: #F8F8F8;
                        color: #546DB9;
                        padding:0.25rem 0;
                        font-weight: bold;
                        text-align: center;
                    }
                }
                
            }
            .app-cerifi{
                margin: 0.2rem 0.5rem;
                .center{
                    color: #6C727E;
                    font-size: 14px;
                    >div{
                        margin-bottom: 0.5rem;
                    }
                }
                .right{
                    img{
                        width:100%;
                    }
                }
            }
            .detail-bg{
                margin: 0.2rem 4%;
                padding:0.3rem 0 0;
                background-image: url("../assets/imgs/detail01.png");
                background-size: 100% 100%;
                >div{
                    color: #212C67;
                    font-size: 16px;
                    font-weight: bold;
                    padding: 0.05rem 0;
                    text-align: center;
                    &:nth-child(2n){
                        color: #6C727E;
                        font-size: 14px;
                    }
                }
            }
            .detail-List-app{
                margin: 0.4rem 4%;
                >div{
                    img{
                        width: 100%;
                    }
                   >div{
                       margin: 0.2rem 0;
                               &:first-child{
                                   color: #6A98D1;
                                   font-size: 16px;
                               }
                               &:nth-child(2){
                                   color: #6C727E;
                                   font-size: 14px;
                               }
                           }
                }
            }
        }
    }
            
}
@media screen and (min-width: 768px){
    .brand{
        .banner-div{
        position: relative;
        img{
            width: 100%;
            height: 5rem;
        }
        .banner-info{
            position: absolute;
            top:30%;
            left: 8%;
            color: #fff;
            div{
                &:first-child{
                font-size: 26px;
                }
                &:nth-child(2){
                font-size: 18px;
                }
                &:nth-child(3){
                font-size: 16px;
                margin-top: 0.1rem;
                }
            }
            }
        }
        .brand-content{
                .tab-list{
                    display: flex;
                    div{
                        flex:1;
                        text-align: center;
                        color: #6C727E;
                        font-weight: bold;
                        font-size: 18px;
                        padding: 0.2rem 0;
                        border-bottom: 1px solid #D2E0EB;
                        cursor: pointer;
                    }
                    .active{
                        color: #3D56A3;
                    }
                }
            .title{
                font-size: 22px;
                font-weight: bold;
                color: #122C5B;
                text-align: center;
                margin-top: 0.2rem;
            }
            .title-eng{
                font-size: 16px;
                color: #4676B1;
                text-align: center;
                margin: 0 0 0.2rem;
            }
            .info{
                margin: 0.2rem 8%;
                            font-size: 14px;
                            color: #6C727E;
                            span{
                                font-size: 16px;
                                color: #6A98D1;
                            }
                        }
                        .upload{
                display: flex;
                align-items: center;
                justify-content: space-between;
                text-align: center;
                width: 70%;
                margin: auto;
                .left-btn,.right-btn{
                    margin: 0.2rem 0.5rem;
                    img{
                        width: 2rem;
                        height: 2rem;
                        margin-bottom: 0.1rem;
                    }
                    div{
                        padding:0.15rem 0.35rem;
                        background: #546DB9;
                        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
                        border-radius: 30px;
                        color: #fff;
                        font-size: 16px;
                    }
                }
            }
            .brand-info{
                width: 84%;
                margin: 0.2rem 8%;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
            .brand-nums{
                font-size: 12px;
                color: #6C727E;
                text-align: center;
                    span{
                        font-size: 14px;
                        color: #6A98D1;
                        font-weight: bold;
                    }
            }
              .fund-detail{
                margin: 0.3rem 8%;
                font-size: 14px;
                color: #6C727E;
                    span{
                        font-size: 16px;
                        color: #6A98D1;
                    }
            }
            .detail-title{
                font-size: 20px;
                font-weight: bold;
                color: #122C5B;
                text-align: center;
            }
            .detail-item{
                display: flex;
                text-align: center;
                margin: 0.2rem;
                justify-content: center;
                >div{
                     margin: 0.2rem;
                   img{
                    width: 2.5rem;
                    }
                    div{
                        background-color: #F8F8F8;
                        color: #546DB9;
                        padding:0.1rem 0;
                        font-weight: bold;
                        width: 2.5rem;
                        margin: auto;
                    }
                }
                
            }
            .cerifi-flex{
                display: flex;
                margin: 0.2rem 6%;
                justify-content: center;
                .left{
                    >div{
                        height: 1rem;
                       position: relative;
                       border-left: 1px dotted #B9CEDE;
                       width: 0.3rem;
                    }
                    >div::before{
                        content: "";
                        position: absolute;
                        top:0;
                        left: -0.07rem;
                        width: 10px;
                        height: 10px;
                        background: #B9CEDE;
                        border-radius: 50%;
                    }
                }
                .center{
                    color: #6C727E;
                    font-size: 14px;
                    margin-top: -0.025rem;
                    >div{
                        height: 1rem;
                    }
                }
                .right{
                    img{
                        width: 2.5rem;
                        margin-left: 0.2rem;
                    }
                }
            }
            .detail-bg{
                margin: 0.2rem 6%;
                padding:0.3rem 0 0;
                background-image: url("../assets/imgs/detail01.png");
                background-size: 100% 100%;
                >div{
                    color: #212C67;
                    font-size: 16px;
                    font-weight: bold;
                    padding: 0.05rem 0;
                    text-align: center;
                    &:nth-child(2n){
                        color: #6C727E;
                        font-size: 14px;
                    }
                }
            }
            .detail-List{
                display: flex;
                flex-wrap: wrap;
                margin: 0.4rem 6%;
                align-items: center;
                >div{
                    width: calc(46% - 0.4rem);
                    margin:0 0.2rem;
                    img{
                        width: 100%;
                    }
                   >div{
                               &:first-child{
                                   color: #6A98D1;
                                   font-size: 16px;
                               }
                               &:nth-child(2){
                                   color: #6C727E;
                                   font-size: 14px;
                               }
                           }
                }
            }
        }
    }
    .toast-qrcode{
        position: fixed;
        top:0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(#212C67,0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        img{
            width: 3rem;
            height: 3rem;
        }
    }     
            
}
@media screen and (min-width: 1200px){
    .toast-qrcode{
        position: fixed;
        top:0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(#212C67,0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        img{
            width: 2rem;
            height: 2rem;
        }
    }
    .brand{
        .banner-div{
        position: relative;
        img{
            width: 100%;
            height: 3rem;
        }
        .banner-info{
            position: absolute;
            top:40%;
            left: 8%;
            color: #fff;
            div{
                &:first-child{
                font-size: 26px;
                }
                &:nth-child(2){
                font-size: 18px;
                }
                &:nth-child(3){
                font-size: 16px;
                margin-top: 0.1rem;
                }
            }
            }
        }
        .brand-content{
            .tab-list{
                display: flex;
                div{
                    flex:1;
                    text-align: center;
                    color: #6C727E;
                    font-weight: bold;
                    font-size: 18px;
                    padding: 0.2rem 0;
                    border-bottom: 1px solid #D2E0EB;
                    cursor: pointer;
                }
                .active{
                    color: #3D56A3;
                }
            }
            .title{
                font-size: 24px;
                font-weight: bold;
                color: #122C5B;
                text-align: center;
                margin-top: 0.2rem;
            }
            .title-eng{
                font-size: 16px;
                color: #4676B1;
                text-align: center;
                margin: 0 0 0.2rem;
            }
            .info{
                margin: 0.2rem 8%;
                font-size: 14px;
                color: #6C727E;
                span{
                    font-size: 16px;
                    color: #6A98D1;
                }
            }
            .upload{
                display: flex;
                align-items: center;
                justify-content: space-between;
                text-align: center;
                width: 70%;
                margin: auto;
                .left-btn,.right-btn{
                    margin: 0.2rem 0.5rem;
                    img{
                        width: 1.5rem;
                        height: 1.5rem;
                        margin-bottom: 0.1rem;
                    }
                    div{
                        padding:0.1rem 0.3rem;
                        background: #546DB9;
                        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
                        border-radius: 30px;
                        color: #fff;
                        font-size: 16px;
                    }
                }
            }
            .brand-info{
                width: 84%;
                margin: 0.2rem 8%;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
            .brand-nums{
                font-size: 14px;
                color: #6C727E;
                text-align: center;
                    span{
                        font-size: 16px;
                        color: #6A98D1;
                        font-weight: bold;
                    }
            }
            .fund-detail{
                margin: 0.3rem 8%;
                font-size: 14px;
                color: #6C727E;
                    span{
                        font-size: 16px;
                        color: #6A98D1;
                    }
            }
            .detail-title{
                font-size: 20px;
                font-weight: bold;
                color: #122C5B;
                text-align: center;
            }
            .detail-item{
                display: flex;
                text-align: center;
                margin: 0.2rem;
                justify-content: center;
                >div{
                     margin: 0.2rem;
                   img{
                    width: 2rem;
                    }
                    div{
                        background-color: #F8F8F8;
                        color: #546DB9;
                        padding:0.1rem 0;
                        font-weight: bold;
                        width: 2rem;
                        margin: auto;
                    }
                }
                
            }
            .cerifi-flex{
                display: flex;
                margin: 0.2rem 8%;
                justify-content: center;
                .left{
                    >div{
                        height: 0.6rem;
                       position: relative;
                       border-left: 1px dotted #B9CEDE;
                       width: 0.2rem;
                    }
                    >div::before{
                        content: "";
                        position: absolute;
                        top:0;
                        left: -0.035rem;
                        width: 10px;
                        height: 10px;
                        background: #B9CEDE;
                        border-radius: 50%;
                    }
                }
                .center{
                    color: #6C727E;
                    font-size: 14px;
                    margin-top: -0.025rem;
                    >div{
                        height: 0.6rem;
                    }
                }
                .right{
                    img{
                        width: 2rem;
                        margin-left: 0.2rem;
                    }
                }
            }
            .detail-bg{
                margin: 0.2rem 8%;
                padding:0.3rem 0 0;
                background-image: url("../assets/imgs/detail01.png");
                background-size: 100% 100%;
                >div{
                    color: #212C67;
                    font-size: 16px;
                    font-weight: bold;
                    padding: 0.05rem 0;
                    text-align: center;
                    &:nth-child(2n){
                        color: #6C727E;
                        font-size: 14px;
                    }
                }
            }
            .detail-List{
                display: flex;
                flex-wrap: wrap;
                margin: 0.2rem 8%;
                align-items: center;
                >div{
                    width: calc(46% - 0.4rem);
                    margin:0 0.2rem;
                    img{
                        width: 100%;
                    }
                   >div{
                               &:first-child{
                                   color: #6A98D1;
                                   font-size: 16px;
                               }
                               &:nth-child(2){
                                   color: #6C727E;
                                   font-size: 14px;
                               }
                           }
                }
            }
        }
    }
}
.brand{
    padding-top: 70px;
    .nav-title{
        position: fixed;
        top:0;
        z-index: 100;
    }
}

</style>