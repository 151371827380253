<template>
    <div class="home-div">
        <Nav-header class="nav-title"></Nav-header>
        <swiper id="swiperBox" :options="swiperOption" ref="mySwiper">
            <swiper-slide class="swiper-slide" v-if="screenWidth>=768">
                <div class="content-div1">
                    <!-- <div class="play-btn">
                        <img src="../assets/imgs/play.png" alt="">
                    </div> -->
                    <div class="content_img">
                        <img src="../assets/imgs/first-bg-text.png" alt="">
                    </div>
                </div>
               
                <!-- <video src="https://haokan.baidu.com/v?vid=17118008657220499665&pd=bjh&fr=bjhauthor&type=video" controls></video> -->
            </swiper-slide>
            <swiper-slide class="swiper-slide" v-else style="padding-top:70px">
                <div class="content-div1">
                    <!-- <div class="play-btn">
                        <img src="../assets/imgs/play.png" alt="">
                    </div> -->
                    <div class="content_img">
                        <img src="../assets/imgs/first-bg-text.png" alt="">
                    </div>
                </div>
                <div class="content-div2">
                    <div class="second-div">
                        <div>关于集团</div>
                        <div>ABOUT US</div>
                        <div>总部位于上海陆家嘴，公司布局覆盖 <span>19+</span> 省市，并在香港、新加坡等地区设有办公室
                            <div>上海 ·北京·重庆    江苏 ·浙江·广东    安徽 ·湖北·山东    香港 ·新加坡</div> 
                        </div>
                    </div>
                    <div class="mark-bg">
                        <img src="../assets/imgs/mark.png" alt="">
                    </div>
                    <div class="more-btn" @click="getMoreCompany">
                        <span>了解更多集团详情</span> 
                    </div>
                </div>
            </swiper-slide>
            <swiper-slide class="swiper-slide" v-if="screenWidth>=768">
                <div class="content-div2">
                    <div class="second-div">
                        <div>关于集团</div>
                        <div>ABOUT US</div>
                        <div>总部位于上海陆家嘴，公司布局覆盖 <span>19+</span> 省市，并在香港、新加坡等地区设有办公室
                            <div>上海 ·北京·重庆    江苏 ·浙江·广东    安徽 ·湖北·山东    香港 ·新加坡</div> 
                        </div>
                    </div>
                    <div class="mark-bg">
                        <img src="../assets/imgs/mark.png" alt="">
                    </div>
                    <div class="more-btn" @click="getMoreCompany">
                        <span>了解更多集团详情</span> 
                    </div>
                </div>
            </swiper-slide>
            <swiper-slide class="swiper-slide">
                <div class="content-div3">
                    <div class="second-div">
                        <div>旗下品牌-简基金</div>
                        <div>BRANDS</div>
                    </div>
                    <div class="phone-bg">
                        <img v-if="screenWidth>=768" src="../assets/imgs/phone.png" alt="">
                        <img v-else src="../assets/imgs/phone-app.png" alt="">
                    </div>
                    <div class="btns-upload">
                        <div @click="uploadVisible = true">IOS下载</div>
                        <div @click="uploadVisible = true">Android下载</div>
                    </div>
                </div>
            </swiper-slide>
            <swiper-slide class="swiper-slide">
                <div class="content-div4">
                    <div class="second-div">
                        <div style="color:#fff;">旗下品牌-方德保险</div>
                        <div style="color:#fff;">BRANDS</div>
                    </div>
                    <div class="brand-nums">全国分支机构覆盖 <span>19+</span>省   <span>1000+</span>保险代理人   共 <span>30+</span>分支机构</div>
                    <div class="brand-content">
                        <div class="first-line row">
                            <div class="col-xs-12 col-sm-6">
                                <img src="../assets/imgs/brand1.png" alt="">
                                <img src="../assets/imgs/brand2.png" alt="">
                            </div>
                            <div class="col-xs-12 col-sm-6">
                                <div><span>方德保险代理</span>有限公司是全国<span>仅有</span>的<span>五家</span>持有<span>【基金销售牌照】</span>的保险代理公司之一</div>
                                <img src="../assets/imgs/brand5.png" alt="">
                            </div>
                        </div>
                        <div class="second-line row">
                            <div class="col-xs-12 col-sm-6">
                                <div><span>方德保险代理</span>有限公司专注保险服务<span>15年</span>，为<span>10万</span>客户提供更优质的保障服务，整合超过<span>五十家</span>产寿险公司的<span>上千款产品</span>，愿景是成为符合华人的最佳保险产品与独立保代服务平台。</div>
                            </div>
                            <div class="col-xs-12 col-sm-6">
                                <img src="../assets/imgs/brand3.png" alt="">
                                <img src="../assets/imgs/brand4.png" alt="">
                            </div>
                            
                        </div>
                    </div>
                    <div class="more-btn" @click="jumpBrand">
                        <span>了解更多保险服务</span> 
                    </div>
                </div>
            </swiper-slide>
            <swiper-slide class="swiper-slide">
                <div class="content-div5">
                    <div class="second-div">
                        <div>业务模式</div>
                        <div>BUSINESS MODEL</div>
                    </div>
                    <div class="model">解决资产端和资金端的产品供应、资源对接以及<span>流量获取</span> & <span>用户激活</span> & <span>流量变现</span>等营销问题 <br/>解决<span>资产端</span>和<span>资金端</span>信息不对称的<span>难题</span>，建立科学评价体系，提高业务对接效率,建立财商教育体系，引导投资者进行<span>智能投顾</span>，同时设有智能调仓预警体系及舆情监测体系</div>
                    <div class="mode-content">
                        <div class="mode-item">
                                    <img src="../assets/imgs/mode1.png" alt="">
                                    <div>
                                        <div>全流程运营管理</div>
                                        <div>产品供应管理</div>
                                        <div>产品交易结算</div>
                                        <div>营销模式构建</div>
                                        <div>品牌塑造推介</div>
                                    </div>
                                </div>
                        <div class="mode-item">
                                    <img src="../assets/imgs/mode2.png" alt="">
                                    <div>
                                        <div>全维度评价体系</div>
                                        <div>资产端</div>
                                        <div>建立资产管理机构评价体系</div>
                                        <div>资金端</div>
                                        <div>建立家办等机构评价体系</div>
                                    </div>
                                </div>
                        <div class="mode-item">
                                    <img src="../assets/imgs/mode3.png" alt="">
                                    <div>
                                        <div>全场景智能投顾</div>
                                        <div>投资者教育获客工具自动生成</div>
                                        <div>KOL定制基金组合</div>
                                        <div>智能预警与调仓</div>
                                        <div>7*24舆情监控与追踪</div>
                                    </div>
                                </div>
                    </div>
                </div>
            </swiper-slide>
            <swiper-slide class="swiper-slide">
                <div class="content-div6">
                    <div class="second-div">
                        <div style="color:#fff">合作伙伴</div>
                        <div style="color:#B9CEDE">COOPERATIVE PARTNER</div>
                    </div>
                    <div class="partner">目前已与<span>基金、信托、保险</span>等行业龙头保持<span>深度合作</span>，致力于建设合作伙伴生态体系，<br/>与合作伙伴及客户建立基于金融服务平台的产品体系和服务体系</div>
                    <div class="cooperative-icons" v-if="screenWidth>=768">
                        <div v-for="(item,index) in icons" :key="index" class="icon-item">
                            <div><img :src="item.iconurl" alt=""></div>
                            <div>{{item.title}}</div>
                        </div>
                    </div>
                    <div class="cooperative-icons" v-else>
                        <div v-for="(item,index) in icons.slice(0,12)" :key="index" class="icon-item">
                            <div><img :src="item.iconurl" alt=""></div>
                            <div>{{item.title}}</div>
                        </div>
                    </div>
                    <div class="more-btn" @click="moreCoop">
                        <span>查看更多合作伙伴</span> 
                    </div>
                </div>
            </swiper-slide>
            <swiper-slide class="swiper-slide">
                <div class="content-div7">
                    <div class="second-div">
                        <div style="color:#fff">加入我们</div>
                        <div style="color:#B9CEDE">JOIN US</div>
                    </div>
                    <div class="form-table" v-if="screenWidth>=768">
                        <el-select v-model="position" placeholder="请选择岗位">
                            <el-option
                            v-for="(item,index) in workList"
                            :key="index"
                            :label="item"
                            :value="item">
                            </el-option>
                        </el-select>
                        <el-input v-model="name" placeholder="请留下您的姓名"></el-input>
                        <el-input v-model="phone" maxlength="11" @input="phone=phone.replace(/[^\d]/g,'')" placeholder="请留下您的电话"></el-input>
                        <div style="display:flex;align-items:center">
                             <div class="upload">
                                请上传您的简历
                                <input accept=".pdf" id="file" type="file" @change="handleFileChange"/>
                            </div>
                            <div style="color:#989CA2;margin-left:10px" v-if="resumeFile">{{resumeFile.name}}</div>
                        </div>
                        <el-input
                        type="textarea"
                        :rows="4"
                        placeholder="请留下您的介绍"
                        v-model="introduce">
                        </el-input>
                        <button @click="submit">确认提交</button>
                    </div>
                    <div class="footer-div" v-if="screenWidth>=768">
                        <div class="first-line">
                            <div>
                                <div>公司地址</div>
                                <div>上海市浦东新区陆家嘴环路166号未来资产大厦</div>
                                <div>北京市东城区东直门外大街46号天恒大厦</div>
                                <div>南京市建邺区白龙江东街9号金鱼嘴基金大厦</div>
                            </div>
                            <div>
                                <div>官方热线</div>
                                <div>服务时间：</div>
                                <div>08:00-21:00</div>
                                <div>010-64068617</div>
                            </div>
                            <div>
                                <div>集团相关平台</div>
                                <div><a style="color: #C3C3C3;" href="http://www.fdsure.com">方德保险代理官网</a></div>
                                <div><a style="color: #C3C3C3;" href="http://www.fundsure.cn">简基金官网</a></div>
                            </div>
                            <div>
                                <div>集团相关公众号</div>
                                <div>
                                    <div>
                                        <div><img src="../assets/imgs/qrcode1.png" alt=""></div>
                                        <div>方德保代</div>
                                    </div>
                                    <div>
                                        <div><img src="../assets/imgs/qrcode2.png" alt=""></div>
                                        <div>简基金</div>
                                    </div>
                                    <div>
                                        <div><img src="../assets/imgs/qrcode3.png" alt=""></div>
                                        <div>财友趣</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="last-line"> Copyright© 2021新方德fdsuretech.com版权所有  <a style="color:#C3C3C3;" href="https://beian.miit.gov.cn">粤ICP备2021084799号</a></div>
                    </div>
                    <div class="joinUs" v-else>
                        <div class="join-title">和一群<span>优秀的人</span>一起工作</div>
                        <div class="info">如果你得到了坐上火箭的机会，别管是什么位置，上去就行<br/>
                            lf you’re offered a seat on a rocket ship, don’t ask what seat. Just get on.<br/>
                            <span>——谷歌 CEO 艾里克·施密特</span>
                        </div>
                        <div class="join-introd">
                            <div>
                                新方德科技集团是一家运用科技助力实业转型升级的创新型<span>国资综合控股集团</span>。
                            </div>
                            <div>
                                集团始终秉承着<span>兼容并包的理念</span>招贤纳士，期待优秀的人才加入，助力集团的发展。
                            </div>
                            <div>
                                集团为每一位员工提供广阔的学习平台和施展才华的机会，工作<span>待遇优厚</span>，带给员工<span>价值感</span>与<span>归属感</span>。
                            </div>
                        </div>
                        
                    </div>
                    <div class="more-btn" v-if="screenWidth<768">
                        <span @click="jumpWork">查看公司招聘职位</span> 
                    </div>
                </div>
            </swiper-slide>
            <swiper-slide v-if="screenWidth<768" class="swiper-slide">
                <div style="padding-top:70px;background: #6D7B8E;">
                    <Footer ></Footer>
                </div>
               
            </swiper-slide>
            <div class="swiper-pagination"  slot="pagination"></div>
        </swiper>
         <div v-if="uploadVisible" class="toast-qrcode" @click="uploadVisible = false">
             <img src="../assets/imgs/qrcode.png" alt="">
         </div>
    </div>
</template>

<script>
import NavHeader from '../components/nav'
import Footer from '../components/footer'
import videojs from 'video.js'
import 'video.js/dist/video-js.css'
import { swiper, swiperSlide } from "vue-awesome-swiper";
export default {
    data(){
        return{
            screenWidth:0,
            swiperOption: {
                notNextTick: true, //notNextTick是一个组件自有属性，如果notNextTick设置为true，组件则不会通过NextTick来实例化swiper，也就意味着你可以在第一时间获取到swiper对象，假如你需要刚加载遍使用获取swiper对象来做什么事，那么这个属性一定要是true
                direction: "vertical", //垂直方向移动
                grabCursor: true, //鼠标覆盖Swiper时指针会变成手掌形状，拖动时指针会变成抓手形状
                setWrapperSize: true, //Swiper使用flexbox布局(display: flex)，开启这个设定会在Wrapper上添加等于slides相加的宽或高，在对flexbox布局的支持不是很好的浏览器中可能需要用到。
                autoHeight: true, //自动高度。设置为true时，wrapper和container会随着当前slide的高度而发生变化
                slidesPerView: 1, //设置slider容器能够同时显示的slides数量(carousel模式)。可以设置为数字（可为小数，小数不可loop），或者 'auto'则自动根据slides的宽度来设定数量。loop模式下如果设置为'auto'还需要设置另外一个参数loopedSlides。
                mousewheel: true, //开启鼠标滚轮控制Swiper切换。可设置鼠标选项，默认值false
                mousewheelControl: true, //同上
                height: window.innerHeight, // 高度设置，占满设备高度
                resistanceRatio: 0, //抵抗率。边缘抵抗力的大小比例。值越小抵抗越大越难将slide拖离边缘，0时完全无法拖离。本业务需要
                observeParents: true, //将observe应用于Swiper的父元素。当Swiper的父元素变化时，例如window.resize，Swiper更新
                // 分页器
                pagination: {
                    el: '.swiper-pagination',
                    bulletClass: 'my-bullet',
                    bulletActiveClass: 'my-bullet-active',
                    clickable: true,
                },
            },
            icons:[
                {title:'昆仑信托',iconurl:require('../assets/imgs/icon1.png')},
                {title:'光大信托',iconurl:require('../assets/imgs/icon2.png')},
                {title:'国民信托',iconurl:require('../assets/imgs/icon3.png')},
                {title:'国通信托',iconurl:require('../assets/imgs/icon4.png')},
                {title:'国元信托',iconurl:require('../assets/imgs/icon5.png')},
                {title:'华鑫信托',iconurl:require('../assets/imgs/icon6.png')},
                {title:'金谷信托',iconurl:require('../assets/imgs/icon7.png')},
                {title:'民生信托',iconurl:require('../assets/imgs/icon8.png')},
                {title:'山西信托',iconurl:require('../assets/imgs/icon9.png')},
                {title:'五矿信托',iconurl:require('../assets/imgs/icon10.png')},
                {title:'长安信托',iconurl:require('../assets/imgs/icon11.png')},
                {title:'西部信托',iconurl:require('../assets/imgs/icon12.png')},
                {title:'中粮信托',iconurl:require('../assets/imgs/icon13.png')},
                {title:'大业信托',iconurl:require('../assets/imgs/icon14.png')},
                {title:'启林基金',iconurl:require('../assets/imgs/icon15.png')},
                {title:'上海耀之',iconurl:require('../assets/imgs/icon16.png')},
                {title:'天演资本',iconurl:require('../assets/imgs/icon17.png')},
                {title:'同犇基金',iconurl:require('../assets/imgs/icon18.png')},
                {title:'希瓦基金',iconurl:require('../assets/imgs/icon19.png')},
                {title:'雪杉基金',iconurl:require('../assets/imgs/icon20.png')},
                {title:'展弘基金',iconurl:require('../assets/imgs/icon21.png')},
                {title:'中邮永安',iconurl:require('../assets/imgs/icon22.png')},
            ],
            value: '',
            uploadVisible:false,
            workList:[],
            name:'',
            position:'',
            phone:'',
            introduce:'',
            resumeFile:''
        }
    },
    components:{
        NavHeader,
        Footer,
        swiper,
        swiperSlide
    },
    computed: {
        swiper() {
            return this.$refs.mySwiper.swiper;
        }
    },
    mounted() {
        this.$nextTick(()=>{
            this.screenWidth = window.innerWidth
        })
        this.getWorkList()
    },
    methods:{
        handleFileChange(e){
            this.resumeFile = e.target.files[0];
            if(this.resumeFile) {
                if(this.resumeFile.size > 1024 * 1024 *100) {
                    this.$message.error('文件大小不能超过100M!');
                } 
            }
        },
        getMoreCompany(){
            this.$router.push({path:'/aboutUs'})
        },
        jumpBrand(){
            this.$router.push({path:'/brand',query:{activeIndex:1}})
        },
        moreCoop(){
            this.$router.push({path:'/aboutUs',query:{activeIndex:2}})
        },
        jumpWork(){
            this.$router.push({path:'/joinUs'})
        },
        getWorkList(){
            this.$http.get('/recruitment/title',{
                params:{}
                }).then(res=>{
                    if(res.data.code == 200){
                        this.workList = res.data.data.rows
                    }else{
                        this.$message.error(res.data.msg);
                    }
            })
        },
        submit(){
            if(!this.position){
                this.$message("请选择岗位");
            }else if(!this.name){
                this.$message("请填写姓名");
            }else if(!this.phone){
                this.$message("请填写手机号");
            }else {
                let formData = new FormData();
                formData.append("resumeFile",this.resumeFile);
                formData.append("position",this.position);
                formData.append("name",this.name);
                formData.append("phone",this.phone);
                formData.append("introduce",this.introduce);
                this.$http.post('/resume/upload',formData,{
                        headers: { "Content-Type": "multipart/form-data" },
                    })
                    .then(res=>{
                        if(res.data.code == 200){
                            this.$message({
                                message: '上传成功',
                                type: 'success'
                            });
                            this.resumeFile=''
                            this.position=''
                            this.name=''
                            this.phone =''
                            this.introduce=''
                        }else{
                            this.$message.error(res.data.msg);
                        }
                })
            }
        }
    }
}
</script>

<style lang="less" scoped>


input{
    color: #989CA2;
}
.col-xs-12{
    padding: 0;
}
@media screen and (max-width: 768px){ 
    .swiper-pagination{
        display: none;
    }
    .toast-qrcode{
    position: fixed;
    top:0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(#212C67,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    img{
        width: 4rem;
        height: 4rem;
    }
}
    .content-div1{
        position: relative;
        width: 100%;
        height: 5rem;
        background-image: url("../assets/imgs/first-bg-app.png");
        background-size: 100% 100%;
        .content_img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            img {
                height: 1.62rem;
                width: 10.45rem;
            }
        }
        .play-btn{
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            img{
                width: 0.64rem;
                height: 0.64rem;
            }
        }
    }
    .content-div2{
        width: 100%;
        height: calc(100% - 5rem);
        background-image: url("../assets/imgs/second-bg-app.png");
        background-size: 100% 100%;
        position: relative;
        .mark-bg{
                position: absolute;
                top:28%;
                left: 5%;
                width: 90%;
                height: 35%;
                img{
                   width: 100%;
                   height: 100%;
                   object-fit: fill; 
                    object-fit: contain;
                    object-fit: scale-down;
                }
        }
         .second-div{
                position: absolute;
                top:3%;
                left: 50%;
                transform: translateX(-50%);
                text-align: center;
                >div{
                    &:first-child{
                        font-size: 20px;
                        font-weight: bold;
                        color: #122C5B;
                    }
                    &:nth-child(2){
                        font-size: 16px;
                        color: #4676B1;
                    }
                    &:nth-child(3){
                        margin-top: 0.4rem;
                        font-size: 14px;
                        color: #56637C;
                        span{
                            font-size: 16px;
                            color: #0394CC;
                            font-weight: bold;
                        }
                        div{
                            margin-top: 0.1rem;
                            color: #0394CC;
                        }
                    }
                }
            }
         .more-btn{
                position: absolute;
                bottom:5%;
                left: 50%;
                transform: translateX(-50%);
                text-align: center;
                span{
                    display: inline-block;
                    width: 5.5rem;
                    height: 1rem;
                    line-height: 0.95rem;
                    background: rgba(#122C5B,0.2);
                    border-radius: 40px;
                    font-size: 14px;
                    color: #FFFFFF;
                    border: 1px solid #FFFFFF;
                }
            }
    }
    .content-div3{
        width: 100%;
        height: 100%;
        background-image: url("../assets/imgs/third-bg-app.png");
        background-size: 100% 100%;
        position: relative;
        .second-div{
                position: absolute;
                top:11%;
                left: 50%;
                transform: translateX(-50%);
                text-align: center;
                >div{
                    &:first-child{
                        font-size: 20px;
                        font-weight: bold;
                        color: #122C5B;
                    }
                    &:nth-child(2){
                        font-size: 16px;
                        color: #4676B1;
                    }
                    &:nth-child(3){
                        margin-top: 0.25rem;
                        font-size: 14px;
                        color: #56637C;
                        span{
                            font-size: 16px;
                            color: #0394CC;
                            font-weight: bold;
                        }
                        div{
                            margin-top: 0.1rem;
                            color: #0394CC;
                        }
                    }
                }
            }
        .phone-bg{
                position: absolute;
                top:23%;
                left: 16%;
                width: 65%;
                height: 55%;
                img{
                   width: 100%;
                   height: 100%;
                }
            }
          .btns-upload{
                position: absolute;
                bottom:5%;
                left: 50%;
                transform: translateX(-50%);
                text-align: center;
                display: flex;
                div{
                    width: 3.8rem;
                    height: 1rem;
                    line-height: 1rem;
                    background: #546DB9;
                    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
                    border-radius: 30px;
                    color: #fff;
                    margin:0 0.2rem;
                }
            }
            
    }
    .content-div4{
        width: 100%;
        height: 100%;
        background-image: url("../assets/imgs/brand-app.png");
        background-size: 100% 100%;
        position: relative;
          .second-div{
                position: absolute;
                top:11%;
                left: 50%;
                transform: translateX(-50%);
                text-align: center;
                >div{
                    &:first-child{
                        font-size: 20px;
                        font-weight: bold;
                        color: #122C5B;
                    }
                    &:nth-child(2){
                        font-size: 16px;
                        color: #4676B1;
                    }
                    &:nth-child(3){
                        margin-top: 0.25rem;
                        font-size: 14px;
                        color: #56637C;
                        span{
                            font-size: 16px;
                            color: #0394CC;
                            font-weight: bold;
                        }
                        div{
                            margin-top: 0.1rem;
                            color: #0394CC;
                        }
                    }
                }
            }
          .brand-nums{
                position: absolute;
                top:20%;
                left: 50%;
                transform: translateX(-50%);
                font-size: 12px;
                color: #FFFFFF;
                span{
                    font-size: 16px;
                    font-weight: bold;
                }
            }
         .brand-content{
                position: absolute;
                top:33%;
                left: 53%;
                transform: translateX(-50%);
                width: 80%;
                .first-line{
                    display: block;
                    width: 100%;
                    >div{
                        &:first-child{
                            width: 100%;
                            display: flex;
                            img{
                                width: 50%;
                                height: 3rem;
                            }
                        }
                        &:nth-child(2){
                            width: 100%;
                            display: flex;
                            align-items: center;
                            font-size: 12px;
                            color: #6C727E;
                            background-color: rgba(#fff, 0.8);
                            padding: 0.15rem;
                            span{
                                color: #00A1DF;
                                font-size: 14px;
                            }
                            img{
                                width: 3rem;
                                height: 2rem;
                            }
                        }
                    }
                }
                .second-line{
                    width: 100%;
                    display: block;
                    >div{
                        &:first-child{
                            display: flex;
                            align-items: center;
                            font-size: 12px;
                            color: #6C727E;
                            background-color: rgba(#fff, 0.8);
                            padding: 0.15rem;
                            width: 100%;
                            span{
                                color: #00A1DF;
                                font-size: 14px;
                            }
                        }
                        &:nth-child(2){
                            display: flex;
                            width: 100%;
                            img{
                                width: 50%;
                                height: 3rem;
                            }
                        }
                    }
                }
            }
             .more-btn{
                position: absolute;
                bottom:5%;
                left: 50%;
                transform: translateX(-50%);
                text-align: center;
                span{
                    display: inline-block;
                    width: 5rem;
                    height: 1rem;
                    line-height: 1rem;
                    background: rgba(#122C5B,0.2);
                    border-radius: 40px;
                    font-size: 14px;
                    color: #FFFFFF;
                    border: 1px solid #FFFFFF;
                }
            }
    }
    .content-div5{
        width: 100%;
        height: 100%;
        background-image: url("../assets/imgs/mode-app.png");
        background-size: 100% 100%;
        position: relative;
        .second-div{
                position: absolute;
                top:11%;
                left: 50%;
                transform: translateX(-50%);
                text-align: center;
                >div{
                    &:first-child{
                        font-size: 20px;
                        font-weight: bold;
                        color: #122C5B;
                    }
                    &:nth-child(2){
                        font-size: 16px;
                        color: #4676B1;
                    }
                }
            }
        .model{
                position: absolute;
                top:25%;
                left: 50%;
                transform: translateX(-50%);
                font-size: 14px;
                color: #56637C;
                width: 85%;
                text-align: center;
                span{
                    font-size: 16px;
                    color: #00A1DF;
                }
            }
            .mode-content{
                position: absolute;
                top:60%;
                display: flex;
                width: 100%;
                padding: 0 0.2rem;
                .mode-item{
                    flex:1;
                    text-align: center;
                    background: rgba(#CAD7E3,0.7);
                    width: calc(32% - 0.2rem);
                    height: 7rem;
                    margin: 0 0.1rem 0.1rem;
                    img{
                        width: 3rem;
                        height: 3rem;
                        margin-top: -1.5rem;
                    }
                    >div{
                        font-size: 12px;
                        color: #6C727E;
                        padding-top: 0.5rem;
                        div{
                            &:first-child{
                                font-size: 12px;
                                font-weight: bold;
                                color: #134AAD;
                                margin: 20px 0;
                            }
                        }
                    }
                }
            }
    }
    .content-div6{
        width: 100%;
        height: 100%;
        background-image: url("../assets/imgs/sixth-bg-app.png");
        background-size: 100% 100%;
        position: relative;
        .second-div{
                position: absolute;
                top:11%;
                left: 50%;
                transform: translateX(-50%);
                text-align: center;
                >div{
                    &:first-child{
                        font-size: 18px;
                        font-weight: bold;
                        color: #122C5B;
                    }
                    &:nth-child(2){
                        font-size: 14px;
                        color: #4676B1;
                    }
                }
            }
            .partner{
                position: absolute;
                top:22%;
                left: 50%;
                transform: translateX(-50%);
                font-size: 12px;
                color: #A2A2A2;
                width: 80%;
                text-align: center;
                span{
                    font-size: 14px;
                    color: #86C9EF;
                }
            }
         .cooperative-icons{
            position: absolute;
            top:37%;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            width: 90%;
            transform: translateX(5%);
            .icon-item{
                width: 33.3%;
                text-align: center;
                margin: 0.3rem 0;
                div{
                    &:first-child{
                        img{
                            width: 1.2rem;
                            height: 1.2rem;
                        }
                    }
                    &:nth-child(2){
                        color: #fff;
                        font-size: 12px;
                        margin-top: 0.2rem;
                    }
                }
            }
        }
         .more-btn{
                position: absolute;
                bottom:5%;
                left: 50%;
                transform: translateX(-50%);
                text-align: center;
                span{
                    display: inline-block;
                    width: 5rem;
                    height: 1rem;
                    line-height: 0.9rem;
                    background: rgba(#122C5B,0.2);
                    border-radius: 40px;
                    font-size: 14px;
                    color: #FFFFFF;
                    border: 2px solid #FFFFFF;
                }
            }
    }
    .content-div7{
        width: 100%;
        height: 100%;
        background-image: url("../assets/imgs/seventh-bg-app.png");
        background-size: 100% 100%;
        position: relative;
         .second-div{
                position: absolute;
                top:11%;
                left: 50%;
                transform: translateX(-50%);
                text-align: center;
                >div{
                    &:first-child{
                        font-size: 18px;
                        font-weight: bold;
                        color: #122C5B;
                    }
                    &:nth-child(2){
                        font-size: 14px;
                        color: #4676B1;
                    }
                }
            }
        .joinUs{
            position: absolute;
            top:20%;
            left: 50%;
            transform: translateX(-50%);
            width: 85%;
            .join-title{
                color: #fff;
                font-size: 16px;
                text-align: center;
                span{
                    color: #86C9EF;
                }
            }
            .info{
                color: #A2A2A2;
                font-size: 14px;
                margin-top: 1rem;
                text-align: center;
                span{
                    text-align: center;
                }
            }
            .join-introd{
                margin-top: 1rem;
                >div{
                    color: #A2A2A2;
                    font-size: 14px;
                    margin: 0.5rem 0;
                    span{
                        color: #86C9EF;
                        font-size: 16px;
                        text-align: center;
                    }
                }
            }
        }
        .more-btn{
                position: absolute;
                bottom:5%;
                left: 50%;
                transform: translateX(-50%);
                text-align: center;
                span{
                    display: inline-block;
                    width: 5.5rem;
                    height: 1rem;
                    line-height: 0.95rem;
                    background: rgba(#122C5B,0.2);
                    border-radius: 40px;
                    font-size: 14px;
                    color: #FFFFFF;
                    border: 1px solid #FFFFFF;
                }
            }
    }
}
@media screen and (min-width: 768px){
    .swiper-pagination{
    position: absolute;
    top:45%;
    right: 20px;
    width: 20px;
    /deep/.my-bullet{
        display: block;
        width: 11px;
        height: 11px;
        background: rgba(255, 255, 255, 0.5);
        border-radius: 50%;
        margin: 10px 0;
    }
     /deep/.my-bullet-active{
        width: 11px;
        height: 25px;
        background: #fff;
        border-radius: 20px;
        margin: 10px 0;
    }
}
    .toast-qrcode{
        position: fixed;
        top:0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(#212C67,0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        img{
            width: 3rem;
            height: 3rem;
        }
    }
    .content-div1{
        width: 100%;
        height: 100%;
        background: url("../assets/imgs/first-bg.png");
        background-size: 100% 100%;
        .content_img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            img {
                height: 0.968rem;
                width: 6.16rem;
            }
        }
        .play-btn{
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                width: 0.64rem;
                height: 0.64rem;
            }
        }
    }
    .content-div2{
        width: 100%;
        height: 100%;
        background-image: url("../assets/imgs/second-bg.png");
        background-size: 100% 100%;
        position: relative;
        .mark-bg{
                position: absolute;
                top:18.5%;
                left: 10%;
                width: 81%;
                height: 45%;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: fill; 
                    object-fit: contain;
                    object-fit: scale-down;
                }
        }
         .second-div{
                position: absolute;
                top:10%;
                left: 50%;
                transform: translateX(-50%);
                text-align: center;
                >div{
                    &:first-child{
                        font-size: 24px;
                        font-weight: bold;
                        color: #122C5B;
                    }
                    &:nth-child(2){
                        font-size: 16px;
                        color: #4676B1;
                    }
                    &:nth-child(3){
                        margin-top: 0.25rem;
                        font-size: 14px;
                        color: #56637C;
                        span{
                            font-size: 16px;
                            color: #0394CC;
                            font-weight: bold;
                        }
                        div{
                            margin-top: 0.1rem;
                            color: #0394CC;
                        }
                    }
                }
            }
         .more-btn{
                position: absolute;
                bottom:5%;
                left: 50%;
                transform: translateX(-50%);
                text-align: center;
                span{
                    display: inline-block;
                    width: 4rem;
                    height: 0.7rem;
                    line-height: 0.7rem;
                    background: rgba(#122C5B,0.2);
                    border-radius: 40px;
                    font-size: 14px;
                    color: #FFFFFF;
                    border: 1px solid #FFFFFF;
                }
            }
        
    }
    .content-div3{
        width: 100%;
        height: 100%;
        background-image: url("../assets/imgs/third-bg.png");
        background-size: 100% 100%;
        position: relative;
        .second-div{
                position: absolute;
                top:10%;
                left: 50%;
                transform: translateX(-50%);
                text-align: center;
                >div{
                    &:first-child{
                        font-size: 24px;
                        font-weight: bold;
                        color: #122C5B;
                    }
                    &:nth-child(2){
                        font-size: 16px;
                        color: #4676B1;
                    }
                }
            }
        .phone-bg{
                position: absolute;
                top:25%;
                left: 9%;
                width: 85%;
                height: 50%;
                img{
                   width: 100%;
                   height: 100%;
                }
            }
        .btns-upload{
                position: absolute;
                bottom:5%;
                left: 50%;
                transform: translateX(-50%);
                text-align: center;
                display: flex;
                div{
                    width: 2rem;
                    height: 0.6rem;
                    line-height: 0.6rem;
                    background: #546DB9;
                    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
                    border-radius: 30px;
                    color: #fff;
                    margin:0 0.2rem;
                }
            }
          
    }
    .content-div4{
        width: 100%;
        height: 100%;
        background-image: url("../assets/imgs/brand.png");
        background-size: 100% 100%;
        position: relative;
          .second-div{
                position: absolute;
                top:10%;
                left: 50%;
                transform: translateX(-50%);
                text-align: center;
                >div{
                    &:first-child{
                        font-size: 24px;
                        font-weight: bold;
                        color: #122C5B;
                    }
                    &:nth-child(2){
                        font-size: 16px;
                        color: #4676B1;
                    }
                    &:nth-child(3){
                        margin-top: 0.25rem;
                        font-size: 14px;
                        color: #56637C;
                        span{
                            font-size: 16px;
                            color: #0394CC;
                            font-weight: bold;
                        }
                        div{
                            margin-top: 0.1rem;
                            color: #0394CC;
                        }
                    }
                }
            }
          .brand-nums{
                position: absolute;
                top:25%;
                left: 50%;
                transform: translateX(-50%);
                font-size: 14px;
                color: #FFFFFF;
                span{
                    font-size: 16px;
                    font-weight: bold;
                }
            }
        .brand-content{
                position: absolute;
                top:50%;
                left: 50%;
                transform: translateX(-50%);
                .first-line{
                    display: flex;
                    >div{
                        &:first-child{
                            width: 5.1rem;
                            display: flex;
                            img{
                                width: 2.55rem;
                                height: 1.65rem;
                            }
                        }
                        &:nth-child(2){
                            width: 5.1rem;
                            display: flex;
                            align-items: center;
                            font-size: 14px;
                            color: #6C727E;
                            background-color: rgba(#fff, 0.8);
                            padding: 0.15rem;
                            span{
                                color: #00A1DF;
                                font-size: 18px;
                            }
                            img{
                                width: 1.26rem;
                                height: 0.95rem;
                            }
                        }
                    }
                }
                .second-line{
                    display: flex;
                    >div{
                        &:first-child{
                            display: flex;
                            align-items: center;
                            font-size: 14px;
                            color: #6C727E;
                            background-color: rgba(#fff, 0.8);
                            padding: 0.15rem;
                            width: 5.1rem;
                            span{
                                color: #00A1DF;
                                font-size: 18px;
                            }
                        }
                        &:nth-child(2){
                            display: flex;
                            width: 5.1rem;
                            img{
                                width: 2.55rem;
                                height: 1.65rem;
                            }
                        }
                    }
                }
            }
             .more-btn{
                position: absolute;
                bottom:5%;
                left: 50%;
                transform: translateX(-50%);
                text-align: center;
                span{
                    display: inline-block;
                    width: 3rem;
                    height: 0.5rem;
                    line-height: 0.45rem;
                    background: rgba(#122C5B,0.2);
                    border-radius: 40px;
                    font-size: 16px;
                    color: #FFFFFF;
                    border: 2px solid #FFFFFF;
                }
            }
    }
    .content-div5{
        width: 100%;
        height: 100%;
        background-image: url("../assets/imgs/mode.png");
        background-size: 100% 100%;
        position: relative;
        .second-div{
                position: absolute;
                top:10%;
                left: 50%;
                transform: translateX(-50%);
                text-align: center;
                >div{
                    &:first-child{
                        font-size: 24px;
                        font-weight: bold;
                        color: #122C5B;
                    }
                    &:nth-child(2){
                        font-size: 16px;
                        color: #4676B1;
                    }
                }
            }
        .model{
                position: absolute;
                top:22%;
                left: 50%;
                transform: translateX(-50%);
                font-size: 14px;
                color: #56637C;
                width: 75%;
                line-height: 0.32rem;
                text-align: center;
                span{
                    font-size: 16px;
                    color: #00A1DF;
                }
            }
            .mode-content{
                position: absolute;
                top:55%;
                left: 50%;
                transform: translateX(-50%);
                display: flex;
                .mode-item{
                    text-align: center;
                    background: rgba(#CAD7E3,0.7);
                    width: 3.2rem;
                    height: 4rem;
                    margin: 0 0.2rem;
                    img{
                        width: 2rem;
                        height: 2rem;
                        margin-top: -1rem;
                    }
                    >div{
                        font-size: 14px;
                        color: #6C727E;
                        padding-top: 0.5rem;
                        div{
                            line-height: 0.32rem;
                            &:first-child{
                                font-size: 16px;
                                font-weight: bold;
                                color: #134AAD;
                                margin: 20px 0;
                            }
                        }
                    }
                }
            }
    }
    .content-div6{
        width: 100%;
        height: 100%;
        background-image: url("../assets/imgs/sixth-bg.png");
        background-size: 100% 100%;
        position: relative;
        .second-div{
                position: absolute;
                top:10%;
                left: 50%;
                transform: translateX(-50%);
                text-align: center;
                >div{
                    &:first-child{
                        font-size: 24px;
                        font-weight: bold;
                        color: #122C5B;
                    }
                    &:nth-child(2){
                        font-size: 16px;
                        color: #4676B1;
                    }
                }
            }
        .partner{
                position: absolute;
                top:25%;
                left: 50%;
                transform: translateX(-50%);
                font-size: 14px;
                color: #A2A2A2;
                width: 72%;
                text-align: center;
                span{
                    font-size: 16px;
                    color: #86C9EF;
                }
            }
            .cooperative-icons{
            position: absolute;
            top:42%;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            width: 90%;
            transform: translateX(5%);
            .icon-item{
                width: 15%;
                text-align: center;
                margin: 0.2rem 0;
                div{
                    &:first-child{
                        img{
                            width: 0.5rem;
                            height: 0.5rem;
                        }
                    }
                    &:nth-child(2){
                        color: #fff;
                        font-size: 12px;
                        margin-top: 0.1rem;
                    }
                }
            }
        }
         .more-btn{
                position: absolute;
                bottom:5%;
                left: 50%;
                transform: translateX(-50%);
                text-align: center;
                span{
                    display: inline-block;
                    width: 3rem;
                    height: 0.55rem;
                    line-height: 0.5rem;
                    background: rgba(#122C5B,0.2);
                    border-radius: 40px;
                    font-size: 16px;
                    color: #FFFFFF;
                    border: 2px solid #FFFFFF;
                }
            }
    }
    .content-div7{
        width: 100%;
        height: 100%;
        background-image: url("../assets/imgs/seventh-bg.png");
        background-size: 100% 100%;
        position: relative;
         .second-div{
                position: absolute;
                top:10%;
                left: 50%;
                transform: translateX(-50%);
                text-align: center;
                >div{
                    &:first-child{
                        font-size: 24px;
                        font-weight: bold;
                        color: #122C5B;
                    }
                    &:nth-child(2){
                        font-size: 16px;
                        color: #4676B1;
                    }
                }
            }
         .form-table{
            position: absolute;
            top:24%;
            left: 10%;
            .upload{
                background: #3C4651;
                border: 1px solid #FFFFFF;
                border-radius: 5px;
                color: #989CA2;
                padding: 0.08rem 0.1rem;
                margin: 0.05rem 0;
                position: relative;
                text-align: center;
                width: 2rem;
                input {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    /*透明度为0*/
                    opacity: 0;
                    cursor: pointer;
                }
            }
            /deep/.el-input__inner{
                background:rgba(#3C4651,0.5) ;
                color: #989CA2;
                margin: 0.1rem 0;
                width: 5rem;
            }
            /deep/.el-button{
                height: 0.6rem;
            }
            /deep/.el-textarea__inner{
                background:rgba(#3C4651,0.5) ;
                color: #989CA2;
                margin: 0.1rem 0;
                width: 8rem;
            }
            /deep/.el-upload{
                display: flex;
                align-items: center;
                margin: 0.1rem 0;
                .el-button--small{
                    margin-right: 0.1rem;
                    height: 0.5rem;
                }
                input{
                    color: #989CA2;
                }
            }
            button{
                background: #3C4651;
                border: 1px solid #FFFFFF;
                border-radius: 5px;
                color: #989CA2;
                padding: 0.08rem 0.3rem;
                margin: 0.1rem 0;
            }
        }
        .footer-div{
            position: absolute;
            bottom:0%;
            left: 0;
            width: 100%;
            .first-line{
                display: flex;
                padding: 0 0.3rem 0.1rem;
                >div{
                    >div{
                        color: #C3C3C3;
                        font-size: 12px;
                        margin-bottom: 0.02rem;
                        &:first-child{
                            font-size: 14px;
                            font-weight: bold;
                            color: #86C9EF;
                            margin-bottom: 0.1rem;
                        }
                    }
                    &:first-child{
                        width: 36%;
                    }
                    &:nth-child(2){
                        width: 20%;
                    }
                    &:nth-child(3){
                        width: 22%;
                    }
                    &:last-child{
                        width: 22%;
                        >div{
                            &:nth-child(2){
                                display: flex;
                                align-items: center;
                                text-align: center;
                                >div{
                                    margin-right: 0.1rem;
                                }
                                img{
                                    width: 0.6rem;
                                    height: 0.6rem;
                                    margin-bottom: 0.05rem;
                                }
                            }
                        }
                    }
                }
            }
            .last-line{
                font-size: 12px;
                color: #C3C3C3;
                text-align: center;
                padding: 0.15rem 0;
                border-top:1px solid #3C4246;
            }
        }
    }
}
@media screen and (min-width: 1024px){
    .swiper-pagination{
    position: absolute;
    top:45%;
    right: 20px;
    width: 20px;
    /deep/.my-bullet{
        display: block;
        width: 11px;
        height: 11px;
        background: rgba(255, 255, 255, 0.5);
        border-radius: 50%;
        margin: 10px 0;
    }
     /deep/.my-bullet-active{
        width: 11px;
        height: 25px;
        background: #fff;
        border-radius: 20px;
        margin: 10px 0;
    }
}
    .toast-qrcode{
        position: fixed;
        top:0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(#212C67,0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        img{
            width: 2rem;
            height: 2rem;
        }
    }
    .content-div1{
        width: 100%;
        height: 100%;
        background: url("../assets/imgs/first-bg.png");
        background-size: 100% 100%;
        .content_img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            img {
                height: 0.968rem;
                width: 6.16rem;
            }
        }
        .play-btn{
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            img{
                width: 64px;
                height: 64px;
            }
        }
    }
    .content-div2{
        width: 100%;
        height: 100%;
        background-image: url("../assets/imgs/second-bg.png");
        background-size: 100% 100%;
        position: relative;
        .mark-bg{
                position: absolute;
                top:16%;
                left: 13%;
                width: 75%;
                height: 50%;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: fill; 
                    object-fit: contain;
                    object-fit: scale-down;
                }
            }
        .second-div{
                position: absolute;
                top:10%;
                left: 50%;
                transform: translateX(-50%);
                text-align: center;
                >div{
                    &:first-child{
                        font-size: 24px;
                        font-weight: bold;
                        color: #122C5B;
                    }
                    &:nth-child(2){
                        font-size: 16px;
                        color: #4676B1;
                    }
                    &:nth-child(3){
                        margin-top: 0.25rem;
                        font-size: 14px;
                        color: #56637C;
                        span{
                            font-size: 16px;
                            color: #0394CC;
                            font-weight: bold;
                        }
                        div{
                            margin-top: 0.1rem;
                            color: #0394CC;
                        }
                    }
                }
            }
         .more-btn{
                position: absolute;
                bottom:5%;
                left: 50%;
                transform: translateX(-50%);
                text-align: center;
                span{
                    display: inline-block;
                    width: 3.5rem;
                    height: .6rem;
                    line-height: 0.6rem;
                    background: rgba(#122C5B,0.2);
                    border-radius: 40px;
                    font-size: 14px;
                    color: #FFFFFF;
                    border: 1px solid #FFFFFF;
                }
            }
    }
        .content-div3{
        width: 100%;
        height: 100%;
        background-image: url("../assets/imgs/third-bg.png");
        background-size: 100% 100%;
        position: relative;
         .second-div{
                position: absolute;
                top:10%;
                left: 50%;
                transform: translateX(-50%);
                text-align: center;
                >div{
                    &:first-child{
                        font-size: 24px;
                        font-weight: bold;
                        color: #122C5B;
                    }
                    &:nth-child(2){
                        font-size: 16px;
                        color: #4676B1;
                    }
                }
            }
        .phone-bg{
                position: absolute;
                top:23%;
                left: 14.5%;
                width: 75%;
                height: 55%;
                img{
                   width: 100%;
                   height: 100%;
                }
            }
            .btns-upload{
                position: absolute;
                bottom:5%;
                left: 50%;
                transform: translateX(-50%);
                text-align: center;
                display: flex;
                div{
                    width: 1.55rem;
                    height: 0.5rem;
                    line-height: 0.5rem;
                    background: #546DB9;
                    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
                    border-radius: 30px;
                    color: #fff;
                    margin:0 0.2rem;
                }
            }
          
    }
    .content-div4{
        width: 100%;
        height: 100%;
        background-image: url("../assets/imgs/brand.png");
        background-size: 100% 100%;
        position: relative;
          .second-div{
                position: absolute;
                top:10%;
                left: 50%;
                transform: translateX(-50%);
                text-align: center;
                >div{
                    &:first-child{
                        font-size: 24px;
                        font-weight: bold;
                        color: #122C5B;
                    }
                    &:nth-child(2){
                        font-size: 16px;
                        color: #4676B1;
                    }
                    &:nth-child(3){
                        margin-top: 0.25rem;
                        font-size: 14px;
                        color: #56637C;
                        span{
                            font-size: 16px;
                            color: #0394CC;
                            font-weight: bold;
                        }
                        div{
                            margin-top: 0.1rem;
                            color: #0394CC;
                        }
                    }
                }
            }
          .brand-nums{
                position: absolute;
                top:25%;
                left: 50%;
                transform: translateX(-50%);
                font-size: 14px;
                color: #FFFFFF;
                span{
                    font-size: 16px;
                    font-weight: bold;
                }
            }
        .brand-content{
                position: absolute;
                top:33%;
                left: 50%;
                transform: translateX(-50%);
                .first-line{
                    display: flex;
                    >div{
                        &:first-child{
                            width: 5.1rem;
                            display: flex;
                            img{
                                width: 2.55rem;
                                height: 1.65rem;
                            }
                        }
                        &:nth-child(2){
                            width: 5.1rem;
                            display: flex;
                            align-items: center;
                            font-size: 14px;
                            color: #6C727E;
                            background-color: rgba(#fff, 0.8);
                            padding: 0.15rem;
                            span{
                                color: #00A1DF;
                                font-size: 18px;
                            }
                            img{
                                width: 1.26rem;
                                height: 0.95rem;
                            }
                        }
                    }
                }
                .second-line{
                    display: flex;
                    >div{
                        &:first-child{
                            display: flex;
                            align-items: center;
                            font-size: 14px;
                            color: #6C727E;
                            background-color: rgba(#fff, 0.8);
                            padding: 0.15rem;
                            width: 5.1rem;
                            span{
                                color: #00A1DF;
                                font-size: 18px;
                            }
                        }
                        &:nth-child(2){
                            display: flex;
                            width: 5.1rem;
                            img{
                                width: 2.55rem;
                                height: 1.65rem;
                            }
                        }
                    }
                }
            }
            .more-btn{
                position: absolute;
                bottom:5%;
                left: 50%;
                transform: translateX(-50%);
                text-align: center;
                span{
                    display: inline-block;
                    width: 2.5rem;
                    height: 0.4rem;
                    line-height: 0.39rem;
                    background: rgba(#122C5B,0.2);
                    border-radius: 40px;
                    font-size: 16px;
                    color: #FFFFFF;
                    border: 2px solid #FFFFFF;
                }
            }
    }
    .content-div5{
        width: 100%;
        height: 100%;
        background-image: url("../assets/imgs/mode.png");
        background-size: 100% 100%;
        position: relative;
        .second-div{
                position: absolute;
                top:10%;
                left: 50%;
                transform: translateX(-50%);
                text-align: center;
                >div{
                    &:first-child{
                        font-size: 24px;
                        font-weight: bold;
                        color: #122C5B;
                    }
                    &:nth-child(2){
                        font-size: 16px;
                        color: #4676B1;
                    }
                }
            }
        .model{
                position: absolute;
                top:22%;
                left: 50%;
                transform: translateX(-50%);
                font-size: 14px;
                color: #56637C;
                width: 60%;
                line-height: 0.25rem;
                text-align: center;
                span{
                    font-size: 16px;
                    color: #00A1DF;
                }
            }
            .mode-content{
                position: absolute;
                top:55%;
                left: 50%;
                transform: translateX(-50%);
                display: flex;
                .mode-item{
                    text-align: center;
                    background: rgba(#CAD7E3,0.7);
                    width: 3rem;
                    height: 3.4rem;
                    margin: 0 0.2rem;
                    img{
                        width: 2rem;
                        height: 2rem;
                        margin-top: -1rem;
                    }
                    >div{
                        font-size: 14px;
                        color: #6C727E;
                        padding-top: 0.5rem;
                        div{
                            line-height: 0.25rem;
                            &:first-child{
                                font-size: 16px;
                                font-weight: bold;
                                color: #134AAD;
                                margin: 20px 0;
                            }
                        }
                    }
                }
            }
    }
    .content-div6{
        width: 100%;
        height: 100%;
        background-image: url("../assets/imgs/sixth-bg.png");
        background-size: 100% 100%;
        position: relative;
        .second-div{
                position: absolute;
                top:10%;
                left: 50%;
                transform: translateX(-50%);
                text-align: center;
                >div{
                    &:first-child{
                        font-size: 24px;
                        font-weight: bold;
                        color: #122C5B;
                    }
                    &:nth-child(2){
                        font-size: 16px;
                        color: #4676B1;
                    }
                }
            }
        .partner{
                position: absolute;
                top:25%;
                left: 50%;
                transform: translateX(-50%);
                font-size: 14px;
                color: #A2A2A2;
                width: 32%;
                line-height: 0.25rem;
                text-align: center;
                span{
                    font-size: 16px;
                    color: #86C9EF;
                }
            }
        .cooperative-icons{
            position: absolute;
            top:42%;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            width: 90%;
            transform: translateX(5%);
            .icon-item{
                width: 11%;
                text-align: center;
                margin: 0.2rem 0;
                div{
                    &:first-child{
                        img{
                            width: 0.5rem;
                            height: 0.5rem;
                        }
                    }
                    &:nth-child(2){
                        color: #fff;
                        font-size: 12px;
                        margin-top: 0.1rem;
                    }
                }
            }
        }
         .more-btn{
                position: absolute;
                bottom:5%;
                left: 50%;
                transform: translateX(-50%);
                text-align: center;
                span{
                    display: inline-block;
                    width: 2.5rem;
                    height: 0.4rem;
                    line-height: 0.39rem;
                    background: rgba(#122C5B,0.2);
                    border-radius: 40px;
                    font-size: 16px;
                    color: #FFFFFF;
                    border: 2px solid #FFFFFF;
                }
            }
    }
    .content-div7{
        width: 100%;
        height: 100%;
        background-image: url("../assets/imgs/seventh-bg.png");
        background-size: 100% 100%;
        position: relative;
        .second-div{
                position: absolute;
                top:10%;
                left: 50%;
                transform: translateX(-50%);
                text-align: center;
                >div{
                    &:first-child{
                        font-size: 24px;
                        font-weight: bold;
                        color: #122C5B;
                    }
                    &:nth-child(2){
                        font-size: 16px;
                        color: #4676B1;
                    }
                }
            }
           .form-table{
            position: absolute;
            top:22%;
            left: 10%;
            .upload{
                background: #3C4651;
                border: 1px solid #FFFFFF;
                border-radius: 5px;
                color: #989CA2;
                padding: 0.08rem 0.1rem;
                margin: 0.05rem 0;
                position: relative;
                text-align: center;
                width: 1.5rem;
                input {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    /*透明度为0*/
                    opacity: 0;
                    cursor: pointer;
                }
            }
            /deep/.el-input__inner{
                background:rgba(#3C4651,0.5) ;
                color: #989CA2;
                margin: 0.05rem 0;
                width: 3rem;
            }
            /deep/.el-textarea__inner{
                background:rgba(#3C4651,0.5) ;
                color: #989CA2;
                margin: 0.05rem 0;
                width: 5rem;
            }
            /deep/.el-upload{
                display: flex;
                align-items: center;
                margin: 0.05rem 0;
                .el-button--small{
                    margin-right: 0.1rem;
                    height: 0.4rem;
                }
                input{
                    color: #989CA2;
                }
            }
            button{
                background: #3C4651;
                border: 1px solid #FFFFFF;
                border-radius: 5px;
                color: #989CA2;
                padding: 0.08rem 0.3rem;
                margin: 0.05rem 0;
            }
        }
        .footer-div{
            position: absolute;
            bottom:0%;
            left: 0;
            width: 100%;
            .first-line{
                display: flex;
                padding: 0 0.3rem 0.1rem;
                >div{
                    >div{
                        color: #C3C3C3;
                        font-size: 14px;
                        margin-bottom: 0.02rem;
                        &:first-child{
                            font-size: 16px;
                            font-weight: bold;
                            color: #86C9EF;
                            margin-bottom: 0.1rem;
                        }
                    }
                    &:first-child{
                        width: 32%;
                    }
                    &:nth-child(2){
                        width: 22%;
                    }
                    &:nth-child(3){
                        width: 23%;
                    }
                    &:last-child{
                        width: 23%;
                        >div{
                            &:nth-child(2){
                                display: flex;
                                align-items: center;
                                text-align: center;
                                >div{
                                    margin-right: 0.1rem;
                                }
                                img{
                                    width: 0.5rem;
                                    height: 0.5rem;
                                    margin-bottom: 0.05rem;
                                }
                            }
                        }
                    }
                }
            }
            .last-line{
                font-size: 14px;
                color: #C3C3C3;
                text-align: center;
                padding: 0.15rem 0;
                border-top:1px solid #3C4246;
            }
        }
    }
}
@media screen and (min-width: 1200px){
    .swiper-pagination{
    position: absolute;
    top:45%;
    right: 20px;
    width: 20px;
    /deep/.my-bullet{
        display: block;
        width: 11px;
        height: 11px;
        background: rgba(255, 255, 255, 0.5);
        border-radius: 50%;
        margin: 10px 0;
    }
     /deep/.my-bullet-active{
        width: 11px;
        height: 25px;
        background: #fff;
        border-radius: 20px;
        margin: 10px 0;
    }
}
    .content-div1{
        background: url("../assets/imgs/first-bg.png");
        background-size: 100% 100%;
        .content_img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            img {
                height: 0.968rem;
                width: 6.16rem;
            }
        }
        .play-btn{
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            img{
                width: 64px;
                height: 64px;
            }
        }
    }
    .content-div2{
        width: 100%;
        height: 100%;
        background-image: url("../assets/imgs/second-bg.png");
        background-size: 100% 100%;
        position: relative;
        .mark-bg{
                position: absolute;
                top:10%;
                left: 20.2%;
                width: 60%;
                height: 60%;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: fill; 
                    object-fit: contain;
                    object-fit: scale-down;
                }
            }
        .second-div{
                position: absolute;
                top:10%;
                left: 50%;
                transform: translateX(-50%);
                text-align: center;
                >div{
                    &:first-child{
                        font-size: 24px;
                        font-weight: bold;
                        color: #122C5B;
                    }
                    &:nth-child(2){
                        font-size: 16px;
                        color: #4676B1;
                    }
                    &:nth-child(3){
                        margin-top: 0.25rem;
                        font-size: 14px;
                        color: #56637C;
                        span{
                            font-size: 16px;
                            color: #0394CC;
                            font-weight: bold;
                        }
                        div{
                            margin-top: 0.1rem;
                            color: #0394CC;
                        }
                    }
                }
            }
         .more-btn{
                position: absolute;
                bottom:5%;
                left: 50%;
                transform: translateX(-50%);
                text-align: center;
                span{
                    display: inline-block;
                    width: 2rem;
                    height: 0.4rem;
                    line-height: 0.4rem;
                    background: rgba(#122C5B,0.2);
                    border-radius: 40px;
                    font-size: 14px;
                    color: #FFFFFF;
                    border: 1px solid #FFFFFF;
                }
            }
    }
        .content-div3{
        width: 100%;
        height: 100%;
        background-image: url("../assets/imgs/third-bg.png");
        background-size: 100% 100%;
        position: relative;
         .second-div{
                position: absolute;
                top:10%;
                left: 50%;
                transform: translateX(-50%);
                text-align: center;
                >div{
                    &:first-child{
                        font-size: 24px;
                        font-weight: bold;
                        color: #122C5B;
                    }
                    &:nth-child(2){
                        font-size: 16px;
                        color: #4676B1;
                    }
                }
            }
        .phone-bg{
                position: absolute;
                top:22%;
                left: 20.3%;
                width: 60%;
                height: 60%;
                img{
                   width: 100%;
                   height: 100%;
                }
            }
            .btns-upload{
                position: absolute;
                bottom:5%;
                left: 50%;
                transform: translateX(-50%);
                text-align: center;
                display: flex;
                div{
                    width: 1.55rem;
                    height: 0.4rem;
                    line-height: 0.4rem;
                    background: #546DB9;
                    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
                    border-radius: 30px;
                    color: #fff;
                    margin:0 0.2rem;
                }
            }
          
    }
    .content-div4{
        width: 100%;
        height: 100%;
        background-image: url("../assets/imgs/brand.png");
        background-size: 100% 100%;
        position: relative;
          .second-div{
                position: absolute;
                top:10%;
                left: 50%;
                transform: translateX(-50%);
                text-align: center;
                >div{
                    &:first-child{
                        font-size: 24px;
                        font-weight: bold;
                        color: #122C5B;
                    }
                    &:nth-child(2){
                        font-size: 16px;
                        color: #4676B1;
                    }
                    &:nth-child(3){
                        margin-top: 0.25rem;
                        font-size: 14px;
                        color: #56637C;
                        span{
                            font-size: 16px;
                            color: #0394CC;
                            font-weight: bold;
                        }
                        div{
                            margin-top: 0.1rem;
                            color: #0394CC;
                        }
                    }
                }
            }
          .brand-nums{
                position: absolute;
                top:25%;
                left: 50%;
                transform: translateX(-50%);
                font-size: 14px;
                color: #FFFFFF;
                span{
                    font-size: 16px;
                    font-weight: bold;
                }
            }
        .brand-content{
                position: absolute;
                top:30%;
                left: 50%;
                transform: translateX(-50%);
                .first-line{
                    display: flex;
                    >div{
                        &:first-child{
                            width: 5.1rem;
                            display: flex;
                            img{
                                width: 2.55rem;
                                height: 1.6rem;
                            }
                        }
                        &:nth-child(2){
                            width: 5.1rem;
                            display: flex;
                            align-items: center;
                            font-size: 14px;
                            color: #6C727E;
                            background-color: rgba(#fff, 0.8);
                            padding: 0.15rem;
                            span{
                                color: #00A1DF;
                                font-size: 18px;
                            }
                            img{
                                width: 1.26rem;
                                height: 0.95rem;
                            }
                        }
                    }
                }
                .second-line{
                    display: flex;
                    >div{
                        &:first-child{
                            display: flex;
                            align-items: center;
                            font-size: 14px;
                            color: #6C727E;
                            background-color: rgba(#fff, 0.8);
                            padding: 0.15rem;
                            width: 5.1rem;
                            span{
                                color: #00A1DF;
                                font-size: 18px;
                            }
                        }
                        &:nth-child(2){
                            display: flex;
                            width: 5.1rem;
                            img{
                                width: 2.55rem;
                                height: 1.65rem;
                            }
                        }
                    }
                }
            }
             .more-btn{
                position: absolute;
                bottom:5%;
                left: 50%;
                transform: translateX(-50%);
                text-align: center;
                span{
                    display: inline-block;
                    width: 2.5rem;
                    height: 0.4rem;
                    line-height: 0.39rem;
                    background: rgba(#122C5B,0.2);
                    border-radius: 40px;
                    font-size: 16px;
                    color: #FFFFFF;
                    border: 2px solid #FFFFFF;
                }
            }
    }
    .content-div5{
        width: 100%;
        height: 100%;
        background-image: url("../assets/imgs/mode.png");
        background-size: 100% 100%;
        position: relative;
        .second-div{
                position: absolute;
                top:10%;
                left: 50%;
                transform: translateX(-50%);
                text-align: center;
                >div{
                    &:first-child{
                        font-size: 24px;
                        font-weight: bold;
                        color: #122C5B;
                    }
                    &:nth-child(2){
                        font-size: 16px;
                        color: #4676B1;
                    }
                }
            }
        .model{
                position: absolute;
                top:20%;
                left: 50%;
                transform: translateX(-50%);
                font-size: 14px;
                color: #56637C;
                width: 50%;
                line-height: 0.25rem;
                text-align: center;
                span{
                    font-size: 16px;
                    color: #00A1DF;
                }
            }
            .mode-content{
                width: 80%;
                position: absolute;
                top:52%;
                left: 53%;
                transform: translateX(-50%);
                display: flex;
                .mode-item{
                    text-align: center;
                    background: rgba(#CAD7E3,0.7);
                    width: 2.5rem;
                    height: 2.5rem;
                    margin: 0 0.2rem;
                    img{
                        width: 1.6rem;
                        height: 1.6rem;
                        margin-top: -0.8rem;
                    }
                    >div{
                        font-size: 14px;
                        color: #6C727E;
                        padding-top: 0.2rem;
                        div{
                            line-height: 0.2rem;
                            &:first-child{
                                font-size: 16px;
                                font-weight: bold;
                                color: #134AAD;
                                margin: 20px 0;
                            }
                        }
                    }
                }
            }
    }
    .content-div6{
        width: 100%;
        height: 100%;
        background-image: url("../assets/imgs/sixth-bg.png");
        background-size: 100% 100%;
        position: relative;
        .second-div{
                position: absolute;
                top:10%;
                left: 50%;
                transform: translateX(-50%);
                text-align: center;
                >div{
                    &:first-child{
                        font-size: 24px;
                        font-weight: bold;
                        color: #122C5B;
                    }
                    &:nth-child(2){
                        font-size: 16px;
                        color: #4676B1;
                    }
                }
            }
        .partner{
                position: absolute;
                top:25%;
                left: 50%;
                transform: translateX(-50%);
                font-size: 14px;
                color: #A2A2A2;
                width: 32%;
                line-height: 0.25rem;
                text-align: center;
                span{
                    font-size: 16px;
                    color: #86C9EF;
                }
            }
        .cooperative-icons{
            position: absolute;
            top:40%;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            width: 90%;
            transform: translateX(5%);
            justify-content: center;
            .icon-item{
                width: 9%;
                text-align: center;
                margin: 0.2rem 0;
                div{
                    &:first-child{
                        img{
                            width: 0.5rem;
                            height: 0.5rem;
                        }
                    }
                    &:nth-child(2){
                        color: #fff;
                        font-size: 12px;
                        margin-top: 0.1rem;
                    }
                }
            }
        }
         .more-btn{
                position: absolute;
                bottom:5%;
                left: 50%;
                transform: translateX(-50%);
                text-align: center;
                span{
                    display: inline-block;
                    width: 2.5rem;
                    height: 0.4rem;
                    line-height: 0.39rem;
                    background: rgba(#122C5B,0.2);
                    border-radius: 40px;
                    font-size: 16px;
                    color: #FFFFFF;
                    border: 2px solid #FFFFFF;
                }
            }
    }
    .content-div7{
        width: 100%;
        height: 100%;
        background-image: url("../assets/imgs/seventh-bg.png");
        background-size: 100% 100%;
        position: relative;
        .second-div{
                position: absolute;
                top:10%;
                left: 50%;
                transform: translateX(-50%);
                text-align: center;
                >div{
                    &:first-child{
                        font-size: 24px;
                        font-weight: bold;
                        color: #122C5B;
                    }
                    &:nth-child(2){
                        font-size: 16px;
                        color: #4676B1;
                    }
                }
            }
        .form-table{
            position: absolute;
            top:20%;
            left: 10%;
            .upload{
                background: #3C4651;
                border: 1px solid #FFFFFF;
                border-radius: 5px;
                color: #989CA2;
                padding: 0.08rem 0.1rem;
                margin: 0.05rem 0;
                position: relative;
                text-align: center;
                width: 1.3rem;
                input {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    /*透明度为0*/
                    opacity: 0;
                    cursor: pointer;
                }
            }
            /deep/.el-input__inner{
                background:rgba(#3C4651,0.5) ;
                color: #989CA2;
                margin: 0.05rem 0;
                width: 3rem;
            }
            /deep/.el-textarea__inner{
                background:rgba(#3C4651,0.5) ;
                color: #989CA2;
                margin: 0.05rem 0;
                width: 4.5rem;
            }
            /deep/.el-upload{
                display: flex;
                align-items: center;
                margin: 0.05rem 0;
                .el-button--small{
                    margin-right: 0.1rem;
                    height: 0.31rem;
                }
                input{
                    color: #989CA2;
                }
            }
            button{
                background: #3C4651;
                border: 1px solid #FFFFFF;
                border-radius: 5px;
                color: #989CA2;
                padding: 0.08rem 0.3rem;
                margin: 0.05rem 0;
            }
        }
        .footer-div{
            position: absolute;
            bottom:0%;
            left: 0;
            width: 100%;
            .first-line{
                display: flex;
                padding: 0 0.3rem 0.1rem;
                >div{
                    >div{
                        color: #C3C3C3;
                        font-size: 14px;
                        margin-bottom: 0.02rem;
                        &:first-child{
                            font-size: 16px;
                            font-weight: bold;
                            color: #86C9EF;
                            margin-bottom: 0.1rem;
                        }
                    }
                    &:first-child{
                        width: 28%;
                    }
                    &:nth-child(2){
                        width: 24%;
                    }
                    &:nth-child(3){
                        width: 24%;
                    }
                    &:last-child{
                        width: 24%;
                        >div{
                            &:nth-child(2){
                                display: flex;
                                align-items: center;
                                text-align: center;
                                >div{
                                    margin-right: 0.1rem;
                                }
                                img{
                                    width: 0.5rem;
                                    height: 0.5rem;
                                    margin-bottom: 0.05rem;
                                }
                            }
                        }
                    }
                }
            }
            .last-line{
                font-size: 14px;
                color: #C3C3C3;
                text-align: center;
                padding: 0.15rem 0;
                border-top:1px solid #3C4246;
            }
        }
    }
}
.home-div{
    width: 100%;
    .nav-title{
        position: fixed;
        top:0;
        z-index: 100;
    }
    video{
        width: 100%;
        height: 100vh;
    }
}
</style>