<template>
    <div class="business">
        <Nav-header class="nav-title"></Nav-header>
        <div class="banner-div">
            <img src="../assets/imgs/mode-banner.png" alt="">
            <div class="banner-info">
                <div>BRANDS</div>
                <div>业务模式</div>
                <div>
                解决资产端和资金端的产品供应<br/>
                资源对接以及流量获取 & 用户激活 & 流量变现等营销问题
                </div>
          </div>
        </div>
        <div class="business-div">
            <div class="tab-list">
                <div :class="{active:activeIndex==index}" v-for="(item,index) in tabList" :key="index" @click="handleTab(index)">{{item}}</div>
            </div>
            <div v-if="activeIndex == 0">
                <div class="title">全流程运营管理</div>
                <div class="title-eng">BUSINESS MODEL</div>
                <div class="info">
                    解决<span>资产端</span>和<span>资金端</span>的产品供应、<span>资源对接</span>以及<span>流量获取</span> & <span>用户激活</span> & <span>流量变现</span>等营销问题
                </div>
                <div class="info-second">
                    作为拥有<span>基金销售牌照</span>的持牌机构，对产品端进行把控，为客户精选优质产品；对产品的交易和结算进行把关，<span>保障产品</span>的售中和售后；建立合伙人模式，<span>高效整合资源</span>；同时由专业的内容策划团队为资产端提供品牌塑造推介服务，提高其产品在市场上的知名度。
                </div>
                <div class="pro-list">
                    <div class="pro-item">
                        <div>
                            <img src="../assets/imgs/model01.png" alt="">
                        </div>
                        <div>产品供应管理</div>
                        <div class="pro-detail">
                            <div>
                                <div>产品尽调</div>
                                <div>多维度审查</div>
                            </div>
                            <div>
                                <div>产品筛选</div>
                                <div>跨部门复核</div>
                            </div>
                            <div>
                                <div>产品上线</div>
                                <div>全流程支持</div>
                            </div>
                        </div>
                    </div>
                    <div class="pro-item">
                        <div>
                            <img src="../assets/imgs/model02.png" alt="">
                        </div>
                        <div>产品交易结算</div>
                        <div class="pro-detail">
                            <div>
                                <div>适当性管理</div>
                                <div>合规为先</div>
                            </div>
                            <div>
                                <div>销售合同签署</div>
                                <div>严格把控</div>
                            </div>
                            <div>
                                <div>柜台交易</div>
                                <div>智能高效</div>
                            </div>
                        </div>
                    </div>
                </div>
                <img class="model-img" src="../assets/imgs/model04.png" alt="">
                <div class="pro-list">
                    <div class="pro-item">
                        <div>
                            <img src="../assets/imgs/model05.png" alt="">
                        </div>
                        <div>营销模式构建</div>
                        <div class="pro-detail">
                            <div>
                                <div>保险事业合伙人模式</div>
                                <div>高效整合资源</div>
                            </div>
                            <div>
                                <div>连锁家族办公室品牌</div>
                                <div>优化家办效率</div>
                            </div>
                        </div>
                    </div>
                    <div class="pro-item">
                        <div>
                            <img src="../assets/imgs/model06.png" alt="">
                        </div>
                        <div>品牌塑造推介</div>
                        <div class="pro-detail">
                            <div>
                                <div>精准定位</div>
                                <div>个人IP定位</div>
                                <div>与视觉设计</div>
                            </div>
                            <div>
                                <div>高质内容</div>
                                <div>投资策略可视化</div>
                                <div>课程化</div>
                            </div>
                            <div>
                                <div>有效传播</div>
                                <div>音视频</div>
                                <div>营销物料设计制作</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="activeIndex == 1">
                 <div class="title">全维度评价体系</div>
                <div class="title-eng">EVALUATION SYSTEM</div>
                <div class="info">
                    解决<span>资产端</span>和<span>资金端</span>信息不对称的难题,<span>建立科学评价</span>体系，提高业务对接效率
                </div>
                <div class="info-second">
                    由专业的投研团队对<span>资产端</span>和<span>资金端</span>建立评价体系，以科学合理的方法和规则，通过多视角、多维度进行评价。评价体系的出台可以完美对接资产端和资金端，解决信息不对称的问题，使其获取充分信息，各取所需，提高买卖效率。
                </div>      
                <img class="model-img" src="../assets/imgs/model07.png" alt="">
                <div class="pro-list">
                    <div class="pro-item">
                        <div>
                            <img src="../assets/imgs/model08.png" alt="">
                        </div>
                        <div>资产端<span>建立资产管理机构评价体系</span></div>
                        <div class="pro-detail">
                            <div>
                                <div>产品尽调</div>
                                <div>多维度审查</div>
                            </div>
                            <div>
                                <div>产品筛选</div>
                                <div>跨部门复核</div>
                            </div>
                            <div>
                                <div>产品上线</div>
                                <div>全流程支持</div>
                            </div>
                        </div>
                    </div>
                    <div class="pro-item">
                        <div>
                            <img src="../assets/imgs/model09.png" alt="">
                        </div>
                        <div>资金端<span>建立家办等机构评价体系</span></div>
                        <div class="pro-detail">
                            <div>
                                <div>适当性管理</div>
                                <div>合规为先</div>
                            </div>
                            <div>
                                <div>销售合同签署</div>
                                <div>严格把控</div>
                            </div>
                            <div>
                                <div>柜台交易</div>
                                <div>智能高效</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="activeIndex == 2">
                <div class="title">全场景智能投顾</div>
                <div class="title-eng">INVESTMENT ADVISTOR</div>
                <div class="info">
                    建立<span>财商教育</span>体系，引导投资者进行<span>智能投顾</span>，同时设有<span>智能调仓预警</span>体系及<span>舆情监测</span>体系
                </div>
                <div class="info-second">
                    旗下已有<span>简基金公募基金平台</span>及<span>财友趣财商教育公众号</span>，通过精细化打造公众号财友趣平台进行财商教育，签约业内<span>知名财经人士</span>进行引流，在简基金平台发布<span>基金组合策略</span>，吸引大众跟投；同时建立<span>智能预警</span>与<span>调仓体系</span>，进行7*24小时舆情监控与追踪体系。
                </div>
                <div class="pro-list">
                    <div class="pro-item">
                        <div>
                            <img src="../assets/imgs/model10.png" alt="">
                        </div>
                        <div>投资者教育获客工具自动生成</div>
                        <div class="pro-detail-li">
                            <div>
                                通过财友趣平台的财商教育进行引流
                            </div>
                            <div>
                                售卖课程赚取课程费
                            </div>
                            <div>
                                引流至基金组合策略进行购买
                            </div>
                        </div>
                    </div>
                    <div class="pro-item">
                        <div>
                            <img src="../assets/imgs/model11.png" alt="">
                        </div>
                        <div>KOL定制基金组合</div>
                        <div class="pro-detail-li">
                            <div>
                                运营简基金公募基金平台
                            </div>
                            <div>
                                人人皆可在平台上发布策略
                            </div>
                            <div>
                                引导大众进行跟投
                            </div>
                            <div>
                                策略管理人可享有一定手续费
                            </div>
                        </div>
                    </div>
                </div>
                <img class="model-img" src="../assets/imgs/model12.png" alt="">
                <div class="pro-list">
                    <div class="pro-item">
                        <div>
                            <img src="../assets/imgs/model13.png" alt="">
                        </div>
                        <div>智能预警与调仓</div>
                        <div class="pro-detail-li">
                            <div>
                                设立智能预警机制
                            </div>
                            <div>
                                无论涨跌，皆有预警
                            </div>
                            <div>
                                随时把握市场行情
                            </div>
                            <div>
                                客户可根据预警机制预设调仓指令
                            </div>
                        </div>
                    </div>
                    <div class="pro-item">
                        <div>
                            <img src="../assets/imgs/model14.png" alt="">
                        </div>
                        <div>7*24舆情监控与追踪</div>
                        <div class="pro-detail-li">
                            <div>
                                把握所投资产的正负舆情，实时监控
                            </div>
                            <div>
                                有重大事件发生，可随时联系机构
                            </div>
                            <div>
                                敏锐捕捉市场机会
                            </div>
                            <div>
                                缓解投资者信息不对称
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>
<script>
    import NavHeader from '../components/nav'
    import Footer from '../components/footer'
    export default {
        data(){
            return{
                tabList:['全流程运营管理','全维度评价体系','全场景智能投顾'],
                activeIndex:0,
            }
        },
        components:{
            NavHeader,
            Footer
        },
        mounted(){
            this.activeIndex = this.$route.query.activeIndex||0
        },
        methods:{
            handleTab(index){
                this.activeIndex=index
            }
        },
         watch:{
            $route(to,from){
                this.$router.go(0)
            }
        },
    }
</script>
<style lang="less" scoped>
@media screen and (max-width: 768px){
     .business{
         .banner-div{
            position: relative;
            img{
                width: 100%;
                height: 5rem;
            }
            .banner-info{
                position: absolute;
                top:20%;
                left: 8%;
                color: #fff;
                div{
                    &:first-child{
                    font-size: 18px;
                    }
                    &:nth-child(2){
                    font-size: 14px;
                    }
                    &:nth-child(3){
                    font-size: 12px;
                    margin-top: 0.1rem;
                    }
                }
            }
        }
        .business-div{
            .tab-list{
                display: flex;
                div{
                    flex:1;
                    text-align: center;
                    color: #6C727E;
                    font-weight: bold;
                    font-size: 14px;
                    padding: 0.5rem 0;
                    border-bottom: 1px solid #D2E0EB;
                    cursor: pointer;
                }
                .active{
                    color: #3D56A3;
                }
            }
             .title{
                font-size: 18px;
                font-weight: bold;
                color: #122C5B;
                text-align: center;
                margin-top: 0.3rem;
            }
            .title-eng{
                font-size: 14px;
                color: #4676B1;
                text-align: center;
                margin: 0 0 0.2rem;
            }
            .info{
                margin: 0.2rem 8%;
                font-size: 12px;
                color: #6C727E;
                text-align: center;
                span{
                    font-size: 14px;
                    color: #6A98D1;
                    font-weight: bold;
                }
            }
            .info-second{
                margin: 0.2rem 8%;
                font-size: 12px;
                color: #6C727E;
                span{
                    font-size: 14px;
                    color: #6A98D1;
                }
            }
            .pro-list{
                display: flex;
                margin: 0.4rem 0;
                .pro-item{
                    flex: 1;
                    text-align: center;
                    >div{
                        &:first-child{
                            img{
                                width: 45%;
                            }
                        }
                        &:nth-child(2){
                            background-image: url("../assets/imgs/model03.png");
                            color: #546DB9;
                            font-size: 12px;
                            font-weight: bold;
                            background-size: 100% 100%;
                            text-align: center;
                            margin: 0.3rem auto;
                            // width: 4.6rem;
                            padding: 0.1rem;
                            span{
                                display: block;
                                font-weight: 400;
                                font-size: 12px;
                                margin-top: 0.02rem;
                            }
                        }
                    }
                    .pro-detail{
                        justify-content: center;
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;
                            >div{
                                margin: 0 0.1rem;
                                >div{
                                    font-size: 12px;
                                    color: #6C727E;
                                    margin: 0.1rem 0;
                                    &:first-child{
                                    color: #6A98D1;
                                    font-size: 12px;
                                    font-weight: bold;
                                    }
                                }
                                
                            }
                        }
                    .pro-detail-li{
                        font-size: 12px;
                        color: #6C727E;
                        margin: 0.1rem 0;
                        div{
                            margin: 0.05rem 0;
                        }
                    }
                }
            }
            .model-img{
                margin: 0.2rem 4%;
                width: 92%;
            }
        }
    }
}
@media screen and (min-width: 768px){
     .business{
        .banner-div{
            position: relative;
            img{
                width: 100%;
                height: 5rem;
            }
            .banner-info{
                position: absolute;
                top:30%;
                left: 8%;
                color: #fff;
                div{
                    &:first-child{
                    font-size: 26px;
                    }
                    &:nth-child(2){
                    font-size: 18px;
                    }
                    &:nth-child(3){
                    font-size: 16px;
                    margin-top: 0.1rem;
                    }
                }
            }
        }
        .business-div{
            .tab-list{
                display: flex;
                div{
                    flex:1;
                    text-align: center;
                    color: #6C727E;
                    font-weight: bold;
                    font-size: 18px;
                    padding: 0.2rem 0;
                    border-bottom: 1px solid #D2E0EB;
                    cursor: pointer;
                }
                .active{
                    color: #3D56A3;
                }
            }
            .title{
                font-size: 22px;
                font-weight: bold;
                color: #122C5B;
                text-align: center;
                margin-top: 0.2rem;
            }
            .title-eng{
                font-size: 16px;
                color: #4676B1;
                text-align: center;
                margin: 0 0 0.2rem;
            }
            .info{
                margin: 0.2rem 8%;
                font-size: 14px;
                color: #6C727E;
                 text-align: center;
                span{
                    font-size: 16px;
                    color: #6A98D1;
                    font-weight: bold;
                }
            }
            .info-second{
                margin: 0.2rem 8%;
                font-size: 14px;
                color: #6C727E;
                span{
                    font-size: 16px;
                    color: #6A98D1;
                }
            }
            .pro-list{
                display: flex;
                margin: 0.2rem 0;
                .pro-item{
                    flex: 1;
                    text-align: center;
                    >div{
                        &:first-child{
                            img{
                                width: 30%;
                            }
                        }
                        &:nth-child(2){
                            background-image: url("../assets/imgs/model03.png");
                            color: #546DB9;
                            font-size: 16px;
                            font-weight: bold;
                            background-size: 100% 100%;
                            text-align: center;
                            margin: 0.2rem auto;
                            width: 2.5rem;
                            padding: 0.1rem;
                            span{
                                display: block;
                                font-weight: 400;
                                font-size: 12px;
                                margin-top: 0.02rem;
                            }
                        }
                    }
                    .pro-detail{
                        justify-content: center;
                        display: flex;
                        align-items: center;
                            >div{
                                margin: 0 0.1rem;
                                >div{
                                    font-size: 16px;
                                    color: #6C727E;
                                    margin: 0.1rem 0;
                                    &:first-child{
                                    color: #6A98D1;
                                    font-size: 16px;
                                    font-weight: bold;
                                    }
                                }
                                
                            }
                        }
                    .pro-detail-li{
                        font-size: 16px;
                        color: #6C727E;
                        margin: 0.1rem 0;
                        div{
                            margin: 0.05rem 0;
                        }
                    }
                }
            }
            .model-img{
                margin: 0.2rem 8%;
                width: 84%;
            }
        }
    }
}
@media screen and (min-width: 1200px){
    .business{
         .banner-div{
            position: relative;
            img{
                width: 100%;
                height: 3rem;
            }
            .banner-info{
                position: absolute;
                top:40%;
                left: 8%;
                color: #fff;
                div{
                    &:first-child{
                    font-size: 26px;
                    }
                    &:nth-child(2){
                    font-size: 18px;
                    }
                    &:nth-child(3){
                    font-size: 16px;
                    margin-top: 0.1rem;
                    }
                }
            }
        }
        .business-div{
            .tab-list{
                display: flex;
                div{
                    flex:1;
                    text-align: center;
                    color: #6C727E;
                    font-weight: bold;
                    font-size: 18px;
                    padding: 0.2rem 0;
                    border-bottom: 1px solid #D2E0EB;
                    cursor: pointer;
                }
                .active{
                    color: #3D56A3;
                }
            }
            .title{
                font-size: 24px;
                font-weight: bold;
                color: #122C5B;
                text-align: center;
                margin-top: 0.2rem;
            }
            .title-eng{
                font-size: 16px;
                color: #4676B1;
                text-align: center;
                margin: 0 0 0.2rem;
            }
            .info{
                margin: 0.2rem 8%;
                font-size: 14px;
                color: #6C727E;
                 text-align: center;
                span{
                    font-size: 16px;
                    color: #6A98D1;
                    font-weight: bold;
                }
            }
            .info-second{
                margin: 0.2rem 8%;
                font-size: 14px;
                color: #6C727E;
                span{
                    font-size: 16px;
                    color: #6A98D1;
                }
            }
            .pro-list{
                display: flex;
                margin: 0.2rem 0;
                .pro-item{
                    flex: 1;
                    text-align: center;
                    >div{
                        &:first-child{
                            img{
                                width: 20%;
                            }
                        }
                        &:nth-child(2){
                            background-image: url("../assets/imgs/model03.png");
                            color: #546DB9;
                            font-size: 16px;
                            font-weight: bold;
                            background-size: 100% 100%;
                            text-align: center;
                            margin: 0.2rem auto;
                            // width: 1.2rem;
                            padding: 0.05rem;
                            span{
                                display: block;
                                font-weight: 400;
                                font-size: 12px;
                                margin-top: 0.02rem;
                            }
                        }
                    }
                    .pro-detail{
                        justify-content: center;
                        display: flex;
                        align-items: center;
                            >div{
                                margin: 0 0.1rem;
                                >div{
                                    font-size: 16px;
                                    color: #6C727E;
                                    margin: 0.1rem 0;
                                    &:first-child{
                                    color: #6A98D1;
                                    font-size: 16px;
                                    font-weight: bold;
                                    }
                                }
                                
                            }
                        }
                    .pro-detail-li{
                        div{
                            margin: 0.05rem 0;
                        }
                        font-size: 16px;
                        color: #6C727E;
                        margin: 0.1rem 0;
                    }
                }
            }
            .model-img{
                margin: 0.2rem 8%;
                width: 84%;
            }
        }
    }
}
.business{
    padding-top: 70px;
    .nav-title{
        position: fixed;
        top:0;
        z-index: 100;
    }
}
</style>