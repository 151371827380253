<template>
    <div class="business">
        <Nav-header class="nav-title"></Nav-header>
        <div class="banner-div">
            <img src="../assets/imgs/join-banner.png" alt="">
            <div class="banner-info">
                <div>JOIN US</div>
                <div>加入我们</div>
                <div>
                和优秀的人一起工作
                </div>
          </div>
        </div>
        <div class="business-div">
            <div class="tab-list">
                <div :class="{active:activeIndex==index}" v-for="(item,index) in tabList" :key="index" @click="handleTab(index)">{{item}}</div>
            </div>
            <div v-if="activeIndex == 0">
                <div class="title">加入我们</div>
                <div class="title-eng">JOIN US</div>
                <div class="join-img">
                    <img src="../assets/imgs/join01.png" alt="">
                </div>
                <div class="title">如果你是</div>
                <div class="select-li">
                    <el-collapse v-model="activeNames">
                        <el-collapse-item :title="item.position" :name="index" v-for="(item,index) in workDetailList" :key="index">
                            <div>职位介绍</div>
                            <div>{{item.positionDesc}}</div>

                            <div style="margin-top:10px">任职要求</div>
                            <div>{{item.positionRequirement}}</div>
                        </el-collapse-item>
                    </el-collapse>
                    <el-pagination
                        v-if="workDetailList.length>10"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="pageNum"
                        :page-sizes="[10, 20, 30, 40]"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="total">
                    </el-pagination>
                </div>
                <div class="title">期待您的加入</div>
                <div class="form-table">
                    <el-select v-model="position" placeholder="请选择岗位">
                            <el-option
                            v-for="(item,index) in workList"
                            :key="index"
                            :label="item"
                            :value="item">
                            </el-option>
                        </el-select>
                        <el-input v-model="name" placeholder="请留下您的姓名"></el-input>
                        <el-input v-model="phone" maxlength="11" @input="phone=phone.replace(/[^\d]/g,'')" placeholder="请留下您的电话"></el-input>
                        <div style="display:flex;align-items:center">
                             <div class="upload">
                                请上传您的简历
                                <input accept=".pdf" id="file" type="file" @change="handleFileChange"/>
                            </div>
                            <div style="color:#989CA2;margin-left:10px" v-if="resumeFile">{{resumeFile.name}}</div>
                        </div>
                        <el-input
                        type="textarea"
                        :rows="5"
                        placeholder="请留下您的介绍"
                        v-model="introduce">
                        </el-input>
                        <button @click="submit">确认提交</button>
                </div>
            </div>
            <div v-if="activeIndex == 1">
                <div class="title">校园招聘</div>
                <div class="title-eng">CAMPUS RECRUITMENT</div>
                <div class="join-img">
                    <img src="../assets/imgs/join02.png" alt="">
                </div>
                <div class="title">如果你是</div>
                <div class="select-li">
                    <el-collapse v-model="activeNames">
                        <el-collapse-item :title="item.position" :name="index" v-for="(item,index) in workDetailList" :key="index">
                            <div>职位介绍</div>
                            <div>{{item.positionDesc}}</div>
                            <div style="margin-top:10px">任职要求</div>
                            <div>{{item.positionRequirement}}</div>
                        </el-collapse-item>
                    </el-collapse>
                     <el-pagination
                        v-if="workDetailList.length>10"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="pageNum"
                        :page-sizes="[10, 20, 30, 40]"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="total">
                    </el-pagination>
                </div>
                <div class="title">期待您的加入</div>
                <div class="form-table">
                    <el-select v-model="position" placeholder="请选择岗位">
                            <el-option
                            v-for="(item,index) in workList"
                            :key="index"
                            :label="item"
                            :value="item">
                            </el-option>
                        </el-select>
                        <el-input v-model="name" placeholder="请留下您的姓名"></el-input>
                        <el-input v-model="phone" maxlength="11" @input="phone=phone.replace(/[^\d]/g,'')" placeholder="请留下您的电话"></el-input>
                         <div style="display:flex;align-items:center">
                             <div class="upload">
                                请上传您的简历
                                <input accept=".pdf" id="file" type="file" @change="handleFileChange"/>
                            </div>
                            <div style="color:#989CA2;margin-left:10px" v-if="resumeFile">{{resumeFile.name}}</div>
                        </div>
                        <el-input
                        type="textarea"
                        :rows="5"
                        placeholder="请留下您的介绍"
                        v-model="introduce">
                        </el-input>
                        <button @click="submit">确认提交</button>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>
<script>
    import NavHeader from '../components/nav'
    import Footer from '../components/footer'
    export default {
        data(){
            return{
                tabList:['加入我们','校园招聘'],
                activeIndex:0,
                activeNames: [0],
                workList:[],
                name:'',
                position:'',
                phone:'',
                introduce:'',
                resumeFile:'',
                workDetailList:[],
                total:0,
                pageSize:10,
                pageNum:1,
            }
        },
        components:{
            NavHeader,
            Footer
        },
        mounted(){
            this.activeIndex = this.$route.query.activeIndex||0
            this.getWorkList()
            this.initWork()
        },
        watch:{
            $route(to,from){
                this.$router.go(0)
            }
        },
        methods:{
            handleTab(index){
                this.activeIndex=index
                this.initWork()
                this.getWorkList()
                this.resumeFile=''
                this.position=''
                this.name=''
                this.phone =''
                this.introduce=''
            },
            initWork(){
                this.$http.get('/recruitment',{
                    params:{type:parseInt(this.activeIndex)+1,pageSize:this.pageSize,pageNum:this.pageNum}
                    }).then(res=>{
                        if(res.data.code == 200){
                            this.workDetailList = res.data.data.rows
                        }else{
                            this.$message.error(res.data.msg);
                        }
                })
            },
            getWorkList(){
                this.$http.get('/recruitment/title',{
                    params:{type:parseInt(this.activeIndex)+1}
                    }).then(res=>{
                        if(res.data.code == 200){
                            this.workList = res.data.data.rows
                        }else{
                            this.$message.error(res.data.msg);
                        }
                })
            },
             handleFileChange(e){
                this.resumeFile = e.target.files[0];
                if(this.resumeFile) {
                    if(this.resumeFile.size > 1024 * 1024 *100) {
                        this.$message.error('文件大小不能超过100M!');
                    } 
                }
            },
            handleSizeChange(val){ //每页多少条
                this.pageSize = val
                this.initWork()
            },
            handleCurrentChange(val){ //当前页
                this.pageNum = val
                this.initWork()
            },
            submit(){
                if(!this.position){
                    this.$message("请选择岗位");
                }else if(!this.name){
                    this.$message("请填写姓名");
                }else if(!this.phone){
                    this.$message("请填写手机号");
                }else {
                    let formData = new FormData();
                    formData.append("resumeFile",this.resumeFile);
                    formData.append("position",this.position);
                    formData.append("name",this.name);
                    formData.append("phone",this.phone);
                    formData.append("introduce",this.introduce);
                    this.$http.post('/resume/upload',formData,{
                            headers: { "Content-Type": "multipart/form-data" },
                        })
                        .then(res=>{
                            console.log(22,res)
                            if(res.data.code == 200){
                                this.$message({
                                    message: '上传成功',
                                    type: 'success'
                                });
                                this.resumeFile=''
                                this.position=''
                                this.name=''
                                this.phone =''
                                this.introduce=''
                            }else{
                                this.$message.error(res.data.msg);
                            }
                    })
                }
            }
        }
    }
</script>
<style lang="less" scoped>
@media screen and (max-width: 768px){
     .business{
         .banner-div{
            position: relative;
            img{
                width: 100%;
                height: 5rem;
            }
            .banner-info{
                position: absolute;
                top:20%;
                left: 8%;
                color: #fff;
                div{
                    &:first-child{
                    font-size: 18px;
                    }
                    &:nth-child(2){
                    font-size: 14px;
                    }
                    &:nth-child(3){
                    font-size: 12px;
                    margin-top: 0.1rem;
                    }
                }
            }
        }
        .business-div{
            .tab-list{
                display: flex;
                div{
                    flex:1;
                    text-align: center;
                    color: #6C727E;
                    font-weight: bold;
                    font-size: 14px;
                    padding: 0.5rem 0;
                    border-bottom: 1px solid #D2E0EB;
                    cursor: pointer;
                }
                .active{
                    color: #3D56A3;
                }
            }
            .title{
                font-size: 18px;
                font-weight: bold;
                color: #122C5B;
                text-align: center;
                margin-top: 0.2rem;
            }
            .title-eng{
                font-size: 14px;
                color: #4676B1;
                text-align: center;
                margin: 0 0 0.2rem;
            }
            .join-img{
                width: 100%;
                img{
                    width: 100%;
                }
            }
            .select-li{
                margin: 0.3rem 10%;
                border-radius: 8px;
                .el-collapse{
                    width: 100%;
                    border: none;
                }
                .el-pagination{
                    margin: 0 -15%;
                    text-align: center;
                }
                /deep/.el-collapse-item__header{
                    background-color: #F7FAFD;
                }
                .el-collapse-item{
                    margin: 0.3rem 0;
                }
                /deep/.el-collapse-item__header{
                    border: none;
                    border-radius: 8px;
                    padding: 0 0.15rem;
                }
                /deep/.el-collapse-item__wrap{
                    background-color: #FAFAFA;
                    border: none;
                    margin: 0.1rem 0;
                    padding: 0.15rem;
                    border-radius: 8px;
                }
            }
            .form-table{
                background-color: #FAFBFF;
                padding: 0.2rem 10%;
                margin: 0.2rem auto 0;
                .upload{
                   background: #fff;
                    border: 1px solid #DCDFE6;
                    border-radius: 5px;
                    color: #989CA2;
                    padding: 0.08rem 0.1rem;
                    margin: 0.2rem 0;
                    position: relative;
                    text-align: center;
                    width: 4rem;
                    input {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        /*透明度为0*/
                        opacity: 0;
                        cursor: pointer;
                    }
                }
                /deep/.el-input__inner{
                    color: #989CA2;
                    margin: 0.15rem 0;
                    width: 8rem;
                }
                /deep/.el-textarea__inner{
                    color: #989CA2;
                    margin: 0.05rem 0;
                    width: 10rem;
                }
                /deep/.el-upload{
                    display: flex;
                    align-items: center;
                    margin: 0.2rem 0;
                    .el-button--small{
                        margin-right: 0.1rem;
                        padding: 0.25rem 0.3rem;
                    }
                    input{
                        color: #989CA2;
                    }
                }
                button{
                    background: #8698CD;
                    border: 1px solid #FFFFFF;
                    border-radius: 5px;
                    color: #fff;
                    padding: 0.08rem 0.3rem;
                    margin: 0.2rem 0;
                }
            }
        }
    }
}
@media screen and (min-width: 768px){
     .business{
        .banner-div{
            position: relative;
            img{
                width: 100%;
                height: 5rem;
            }
            .banner-info{
                position: absolute;
                top:30%;
                left: 8%;
                color: #fff;
                div{
                    &:first-child{
                    font-size: 26px;
                    }
                    &:nth-child(2){
                    font-size: 18px;
                    }
                    &:nth-child(3){
                    font-size: 16px;
                    margin-top: 0.1rem;
                    }
                }
            }
        }
        .business-div{
            .tab-list{
                display: flex;
                div{
                    flex:1;
                    text-align: center;
                    color: #6C727E;
                    font-weight: bold;
                    font-size: 18px;
                    padding: 0.2rem 0;
                    border-bottom: 1px solid #D2E0EB;
                    cursor: pointer;
                }
                .active{
                    color: #3D56A3;
                }
            }
            .title{
                font-size: 22px;
                font-weight: bold;
                color: #122C5B;
                text-align: center;
                margin-top: 0.2rem;
            }
            .title-eng{
                font-size: 16px;
                color: #4676B1;
                text-align: center;
                margin: 0 0 0.2rem;
            }
            .join-img{
                width: 100%;
                img{
                    width: 100%;
                }
            }
            .select-li{
                margin: 0.2rem 10%;
                border-radius: 10px;
                .el-collapse{
                    width: 100%;
                    border: none;
                }
                /deep/.el-collapse-item__header{
                    background-color: #F7FAFD;
                }
                .el-collapse-item{
                    margin: 0.1rem 0;
                }
                /deep/.el-collapse-item__header{
                    border: none;
                    border-radius: 10px;
                    padding: 0 0.15rem;
                }
                /deep/.el-collapse-item__wrap{
                    background-color: #FAFAFA;
                    border: none;
                    margin: 0.1rem 0;
                    padding: 0.15rem;
                    border-radius: 10px;
                }
            }
            .form-table{
                background-color: #FAFBFF;
                padding: 0.2rem 20%;
                margin: 0.2rem auto 0;
                .upload{
                    background: #fff;
                    border: 1px solid #DCDFE6;
                    border-radius: 5px;
                    color: #989CA2;
                    padding: 0.08rem 0.1rem;
                    margin: 0.08rem 0;
                    position: relative;
                    text-align: center;
                    width: 2rem;
                    input {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        /*透明度为0*/
                        opacity: 0;
                        cursor: pointer;
                    }
                }
                /deep/.el-input__inner{
                    color: #989CA2;
                    margin: 0.05rem 0;
                    width: 4rem;
                }
                /deep/.el-textarea__inner{
                    color: #989CA2;
                    margin: 0.05rem 0;
                    width: 6rem;
                }
                /deep/.el-upload{
                    display: flex;
                    align-items: center;
                    margin: 0.05rem 0;
                    .el-button--small{
                        margin-right: 0.1rem;
                    }
                    input{
                        color: #989CA2;
                    }
                }
                button{
                    background: #8698CD;
                    border: 1px solid #FFFFFF;
                    border-radius: 5px;
                    color: #fff;
                    padding: 0.13rem 0.3rem;
                    margin: 0.08rem 0;
                }
            }
        }
    }
}
@media screen and (min-width: 1200px){
    .business{
         .banner-div{
            position: relative;
            img{
                width: 100%;
                height: 3rem;
            }
            .banner-info{
                position: absolute;
                top:40%;
                left: 8%;
                color: #fff;
                div{
                    &:first-child{
                    font-size: 26px;
                    }
                    &:nth-child(2){
                    font-size: 18px;
                    }
                    &:nth-child(3){
                    font-size: 16px;
                    margin-top: 0.1rem;
                    }
                }
            }
        }
        .business-div{
            .tab-list{
                display: flex;
                div{
                    flex:1;
                    text-align: center;
                    color: #6C727E;
                    font-weight: bold;
                    font-size: 18px;
                    padding: 0.2rem 0;
                    border-bottom: 1px solid #D2E0EB;
                    cursor: pointer;
                }
                .active{
                    color: #3D56A3;
                }
            }
            .title{
                font-size: 24px;
                font-weight: bold;
                color: #122C5B;
                text-align: center;
                margin-top: 0.2rem;
            }
            .title-eng{
                font-size: 16px;
                color: #4676B1;
                text-align: center;
                margin: 0 0 0.2rem;
            }
            .join-img{
                width: 100%;
                img{
                    width: 100%;
                }
            }
            .select-li{
                margin: 0.2rem 10%;
                border-radius: 10px;
                .el-collapse{
                    width: 100%;
                    border: none;
                }
                /deep/.el-collapse-item__header{
                    background-color: #F7FAFD;
                }
                .el-collapse-item{
                    margin: 0.1rem 0;
                }
                /deep/.el-collapse-item__header{
                    border: none;
                    border-radius: 10px;
                    padding: 0 0.15rem;
                }
                /deep/.el-collapse-item__wrap{
                    background-color: #FAFAFA;
                    border: none;
                    margin: 0.1rem 0;
                    padding: 0.15rem;
                    border-radius: 10px;
                }
            }
            .form-table{
                background-color: #FAFBFF;
                padding: 0.2rem 30%;
                margin: 0.2rem auto 0;
                .upload{
                    background: #fff;
                    border: 1px solid #DCDFE6;
                    border-radius: 5px;
                    color: #989CA2;
                    padding: 0.08rem 0.1rem;
                    margin: 0.05rem 0;
                    position: relative;
                    text-align: center;
                    width: 1.3rem;
                    input {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        /*透明度为0*/
                        opacity: 0;
                        cursor: pointer;
                    }
                }
                /deep/.el-input__inner{
                    color: #989CA2;
                    margin: 0.05rem 0;
                    width: 3rem;
                }
                /deep/.el-textarea__inner{
                    color: #989CA2;
                    margin: 0.05rem 0;
                    width: 5rem;
                }
                /deep/.el-upload{
                    display: flex;
                    align-items: center;
                    margin: 0.05rem 0;
                    .el-button--small{
                        margin-right: 0.1rem;
                    }
                    input{
                        color: #989CA2;
                    }
                }
                button{
                    background: #8698CD;
                    border: 1px solid #FFFFFF;
                    border-radius: 5px;
                    color: #fff;
                    padding: 0.08rem 0.3rem;
                    margin: 0.05rem 0;
                }
            }
        }
    }
}
.business{
    padding-top: 70px;
    .nav-title{
        position: fixed;
        top:0;
        z-index: 100;
    }
}
</style>