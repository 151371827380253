import Vue from "vue";
import VueRouter from "vue-router";
import Home from '../views/home'
import AboutUs from '../views/aboutUs'
import financialQualification from '../views/financialQualification'
import brand from '../views/brand'
import businessMode from '../views/businessMode'
import news from '../views/new'
import riskControl from '../views/riskControl'
import joinUs from '../views/joinUs'
import newDetail from '../views/newDetail'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: '/home',
    },
    {
        path: '/home',
        name: 'home',
        component: Home,
        meta: {
            title: '首页'
        },
    },
    {
        path: '/aboutUs',
        name: 'AboutUs',
        component: AboutUs,
        meta: {
            title: '关于我们'
        },
    },
    {
        path: '/financialQualification',
        name: 'financialQualification',
        component: financialQualification,
        meta: {
            title: '金融资质'
        },
    },
    {
        path: '/brand',
        name: 'brand',
        component: brand,
        meta: {
            title: '旗下品牌'
        },
    },
    {
        path: '/businessMode',
        name: 'businessMode',
        component: businessMode,
        meta: {
            title: '业务模式'
        },
    },
    {
        path: '/riskControl',
        name: 'riskControl',
        component: riskControl,
        meta: {
            title: '风险控制'
        },
    },
    {
        path: '/news',
        name: 'news',
        component: news,
        meta: {
            title: '新闻中心'
        },
    },
    {
        path: '/newDetail',
        name: 'newDetail',
        component: newDetail,
        meta: {
            title: '新闻中心'
        },
    },
    {
        path: '/joinUs',
        name: 'joinUs',
        component: joinUs,
        meta: {
            title: '加入我们'
        },
    },
]

const router = new VueRouter({
    routes,
    mode: 'hash',
    scrollBehavior(to, from, savePotion) {
        if (savePotion) {
            return savePotion
        } else {
            return {
                x: 0,
                y: 0
            }
        }
    },
})

router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    if (to.meta.title) {
      document.title = to.meta.title;
    }
    next();
})

export default router