<template>
    <div class="business">
        <Nav-header class="nav-title"></Nav-header>
        <div class="banner-div">
            <img src="../assets/imgs/new-banner.png" alt="">
            <div class="banner-info">
                <div>NEWS CENTER</div>
                <div>新闻中心</div>
                <div>
                解决资产端和资金端的产品供应<br/>
                资源对接以及流量获取 & 用户激活 & 流量变现等营销问题
                </div>
          </div>
        </div>
        <div>
            <div class="title">集团要闻</div>
            <div class="title-eng">GROUP NEWS</div>
            <div class="content" v-html="newdetail"></div>
        </div>
        <Footer></Footer>
    </div>
</template>
<script>
import NavHeader from '../components/nav'
import Footer from '../components/footer'

 export default {
        data(){
            return{
               id:'',
               newdetail:''
            }
        },
        components:{
            NavHeader,
            Footer
        },
        created(){
            this.id = this.$route.query.id
            console.log(this.id)
            this.$http.get('/news/content',{
                params:{
                    newsId:this.id
                }
            }).then(res=>{
                if(res.data.code == 200){
                    this.newdetail = res.data.data
                }else{
                    this.$message.error(res.data.msg);
                }
            })
        },
        methods:{
            
        }
    }
</script>
<style lang="less" scoped>
@media screen and (max-width: 768px){
     .business{
         .banner-div{
            position: relative;
            img{
                width: 100%;
                height: 5rem;
            }
            .banner-info{
                position: absolute;
                top:20%;
                left: 8%;
                color: #fff;
                div{
                    &:first-child{
                    font-size: 18px;
                    }
                    &:nth-child(2){
                    font-size: 14px;
                    }
                    &:nth-child(3){
                    font-size: 12px;
                    margin-top: 0.1rem;
                    }
                }
            }
        }
        .title{
                font-size: 18px;
                font-weight: bold;
                color: #122C5B;
                text-align: center;
                margin-top: 0.2rem;
            }
            .title-eng{
                font-size: 14px;
                color: #4676B1;
                text-align: center;
                margin: 0 0 0.2rem;
            }
        .content{
                margin: 0.3rem 6% 0.5rem;
            }
    }
}
@media screen and (min-width: 768px){
     .business{
        .banner-div{
            position: relative;
            img{
                width: 100%;
                height: 5rem;
            }
            .banner-info{
                position: absolute;
                top:30%;
                left: 8%;
                color: #fff;
                div{
                    &:first-child{
                    font-size: 26px;
                    }
                    &:nth-child(2){
                    font-size: 18px;
                    }
                    &:nth-child(3){
                    font-size: 16px;
                    margin-top: 0.1rem;
                    }
                }
            }
        }
       .title{
                font-size: 22px;
                font-weight: bold;
                color: #122C5B;
                text-align: center;
                margin-top: 0.2rem;
            }
            .title-eng{
                font-size: 16px;
                color: #4676B1;
                text-align: center;
                margin: 0 0 0.2rem;
            }
        .content{
                margin: 0.2rem 10%;
            }
    }
}
@media screen and (min-width: 1200px){
    .business{
         .banner-div{
            position: relative;
            img{
                width: 100%;
                height: 3rem;
            }
            .banner-info{
                position: absolute;
                top:40%;
                left: 8%;
                color: #fff;
                div{
                    &:first-child{
                    font-size: 26px;
                    }
                    &:nth-child(2){
                    font-size: 18px;
                    }
                    &:nth-child(3){
                    font-size: 16px;
                    margin-top: 0.1rem;
                    }
                }
            }
        }
       .title{
                font-size: 24px;
                font-weight: bold;
                color: #122C5B;
                text-align: center;
                margin-top: 0.2rem;
            }
            .title-eng{
                font-size: 16px;
                color: #4676B1;
                text-align: center;
                margin: 0 0 0.2rem;
            }
            .content{
                margin: 0.2rem 10%;
            }
    }
}
.business{
    padding-top: 70px;
    .nav-title{
        position: fixed;
        top:0;
        z-index: 100;
    }
}
</style>