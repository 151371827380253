<template>
    <div class="about-us">
        <Nav-header class="nav-title"></Nav-header>
        <div class="banner">
          <img src="../assets/imgs/aboutUs-banner.png" alt="">
          <div class="banner-info">
            <div>ABOUT US</div>
            <div>关于集团</div>
            <div>
              总部位于上海陆家嘴，公司布局覆盖19+ 省市，<br/>
并在香港、新加坡等地区设有办公室<br/>
上海 ·北京·重庆    江苏 ·浙江·广东    安徽 ·湖北·山东    香港 ·新加坡
            </div>
          </div>
        </div>
        <div class="abount-contain">
          <div class="tab-list">
            <div :class="{active:activeIndex==index}" v-for="(item,index) in tabList" :key="index" @click="handleTab(index)">{{item}}</div>
          </div>
          <div class="info" v-if="activeIndex == 0">
            <div class="title">集团介绍</div>
            <div class="title-eng">ABOUT US</div>
            <div><span>新方德科技集团</span>（以下简称“集团”）是一家运用科技助力实业转型升级的创新型国资综合控股集团。集团将创新科技深度应用于构建“金融服务、医疗健康、信息技术、消费升级、材料与高端装备”五大生态圈，以提升金融投研效率、实业服务效率，提升风控水平、降低运营成本。</div>
            <div>基于集团<span>基金销售牌照、全国保险代理牌照、互联网保险销售资质</span>等多牌照优势，立足本土、面向海外，凭借“一个客户、定制产品、一站式专属服务”的综合经营模式，为客户提供安全、可靠的优质资产和全球化大类资产配置解决方案。</div>
            <div>集团秉承初心，着眼未来，将持续为用户提供优质的产品和服务，坚守企业价值观，致力于成为一家受人尊敬、可持续发展的创新型综合集团。</div>
            <div class="img-about">
              <img src="../assets/imgs/aboutus.png" alt="">
            </div>
            <div class="img-prosess">
              <img src="../assets/imgs/about2.png" alt="">
            </div>
          </div>
          <div class="culture" v-if="activeIndex == 1">
            <div class="title">企业文化</div>
            <div class="title-eng">CULTURE</div>
            <div class="info-li">
              <div>使命</div>
              <div>通过<span>“一个客户、定制产品、一站式专属服务”</span>的综合金融经营模式，为客户提供<span>安全、可靠</span>的优质资产。</div>
            </div>
            <div class="info-li">
              <div>愿景</div>
              <div>成为一家<span>受人尊敬</span>的、可持续发展<span>专业</span>的国资综合控股集团。</div>
            </div>
            <div class="detail">
              <div class="detail-title">价值观</div>
              <div>
                <div class="detail-contain">
                  <div>做人</div>
                  <div>
                    <div>务实敢为</div>
                    <div>坦诚守信</div>
                    <div>开放谦逊</div>
                    <div>正直朴实</div>
                  </div>
                </div>
                <div class="detail-contain">
                  <div>做事</div>
                  <div>
                    <div>注重细节</div>
                    <div>高效专业</div>
                    <div>客户至上</div>
                    <div>始终创业</div>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
          <div class="partners" v-if="activeIndex == 2">
            <div class="title">合作伙伴</div>
            <div class="title-eng">PARTNERS</div>
            <div class="partners-list" v-for="(item,index) in partnerList" :key="index">
                <div class="partner-title">{{item.typeTitle}}</div>
                <div class="partner-contain">
                  <div class="item" v-for="(ite,index) in item.icons" :key="index">
                    <div><img :src="ite.iconurl" alt=""></div>
                    <div>{{ite.title}}</div>
                  </div>
                </div>
                
            </div>
          </div>
        </div>
        <Footer></Footer>  
    </div>
</template>
<script>
  import NavHeader from '../components/nav'
  import Footer from '../components/footer'
  export default{
    components: {
      NavHeader,
      Footer
    },
    data(){
      return{
        tabList:['集团介绍','企业文化','合作伙伴'],
        activeIndex:0,
        partnerList:[
          {typeTitle:'信托计划',icons:[
            {title:'大业信托',iconurl:require('../assets/imgs/icon14.png')},
            {title:'光大信托',iconurl:require('../assets/imgs/icon2.png')},
            {title:'国民信托',iconurl:require('../assets/imgs/icon3.png')},
            {title:'国通信托',iconurl:require('../assets/imgs/icon4.png')},
            {title:'国元信托',iconurl:require('../assets/imgs/icon5.png')},
            {title:'华鑫信托',iconurl:require('../assets/imgs/icon6.png')},
            {title:'金谷信托',iconurl:require('../assets/imgs/icon7.png')},
            {title:'昆仑信托',iconurl:require('../assets/imgs/icon1.png')},
            {title:'民生信托',iconurl:require('../assets/imgs/icon8.png')},
            {title:'山西信托',iconurl:require('../assets/imgs/icon9.png')},
            {title:'五矿信托',iconurl:require('../assets/imgs/icon10.png')},
            {title:'长安信托',iconurl:require('../assets/imgs/icon11.png')},
            {title:'西部信托',iconurl:require('../assets/imgs/icon12.png')},
            {title:'中粮信托',iconurl:require('../assets/imgs/icon13.png')}
          ]},
          {typeTitle:'私募基金',icons:[
            {title:'半夏基金',iconurl:require('../assets/imgs/icon46.png')},
            {title:'诚奇基金',iconurl:require('../assets/imgs/icon47.png')},
            {title:'丹羿基金',iconurl:require('../assets/imgs/icon48.png')},
            {title:'合晟资产',iconurl:require('../assets/imgs/icon49.png')},
            {title:'洪运瑞恒',iconurl:require('../assets/imgs/icon50.png')},
            {title:'雷根基金',iconurl:require('../assets/imgs/icon51.png')},
            {title:'林园基金',iconurl:require('../assets/imgs/icon54.png')},
            {title:'龙航资产',iconurl:require('../assets/imgs/icon53.png')},
            {title:'明世基金',iconurl:require('../assets/imgs/icon52.png')},
            {title:'启林基金',iconurl:require('../assets/imgs/icon15.png')},
            {title:'上海耀之',iconurl:require('../assets/imgs/icon16.png')},
            {title:'天演资本',iconurl:require('../assets/imgs/icon17.png')},
            {title:'同犇基金',iconurl:require('../assets/imgs/icon18.png')},
            {title:'希瓦基金',iconurl:require('../assets/imgs/icon19.png')},
            {title:'雪杉基金',iconurl:require('../assets/imgs/icon20.png')},
            {title:'展弘基金',iconurl:require('../assets/imgs/icon21.png')},
            {title:'中邮永安',iconurl:require('../assets/imgs/icon22.png')}
          ]},
          {typeTitle:'公募基金',icons:[
            {title:'北信瑞丰',iconurl:require('../assets/imgs/icon23.png')},
            {title:'财通基金',iconurl:require('../assets/imgs/icon24.png')},
            {title:'长盛基金',iconurl:require('../assets/imgs/icon26.png')},
            {title:'大成基金',iconurl:require('../assets/imgs/icon27.png')},
            {title:'东方基金',iconurl:require('../assets/imgs/icon28.png')},
            {title:'东吴基金',iconurl:require('../assets/imgs/icon29.png')},
            {title:'方正富邦基金',iconurl:require('../assets/imgs/icon30.png')},
            {title:'富荣基金',iconurl:require('../assets/imgs/icon31.png')},
            {title:'工银瑞信',iconurl:require('../assets/imgs/icon32.png')},
            {title:'广发基金',iconurl:require('../assets/imgs/icon33.png')},
            {title:'国寿安保',iconurl:require('../assets/imgs/icon34.png')},
            {title:'融通基金',iconurl:require('../assets/imgs/icon35.png')},
            {title:'华泰柏瑞基金',iconurl:require('../assets/imgs/icon36.png')},
            {title:'嘉合基金',iconurl:require('../assets/imgs/icon37.png')},
            {title:'信达证券',iconurl:require('../assets/imgs/icon38.png')},
            {title:'银河基金',iconurl:require('../assets/imgs/icon39.png')},
            {title:'中邮基金',iconurl:require('../assets/imgs/icon40.png')}
          ]},
          {typeTitle:'债券',icons:[
            {title:'成兴国投',iconurl:require('../assets/imgs/icon41.png')},
            {title:'建湖城投',iconurl:require('../assets/imgs/icon44.png')},
            {title:'镇江文旅',iconurl:require('../assets/imgs/icon45.png')}
          ]},
          {typeTitle:'资管',icons:[
            {title:'先锋期贷',iconurl:require('../assets/imgs/icon42.png')},
            {title:'中电投',iconurl:require('../assets/imgs/icon43.png')},
            {title:'财通资管',iconurl:require('../assets/imgs/icon25.png')}
          ]}
        ]
      }
    },
    created(){
      this.activeIndex = this.$route.query.activeIndex || 0
    },
    watch:{
      $route(to,from){
        this.$router.go(0)
      },
      
    },
    methods:{
      handleTab(index){
        this.activeIndex=index
      }
    }
  } 
</script>
<style lang="less" scoped>
@media screen and (max-width: 768px){
  .banner{
    width: 100%;
    height: 5rem;
    position: relative;
     .banner-info{
      position: absolute;
      top:20%;
      left: 8%;
      color: #fff;
      div{
        &:first-child{
          font-size: 18px;
        }
        &:nth-child(2){
          font-size: 14px;
        }
        &:nth-child(3){
          font-size: 12px;
          margin-top: 0.1rem;
        }
      }
    }
  }
  .about-us{
  .banner{
   
    img{
      width: 100%;
      height: 100%;
    }
   
  }
  .title{
    font-size: 20px;
    font-weight: bold;
    color: #122C5B;
    text-align: center;
  }
  .title-eng{
    font-size: 14px;
    color: #4676B1;
    text-align: center;
    margin: 0 0 0.2rem;
  }
  .abount-contain{
    width: 100%;
    .tab-list{
      display: flex;
      div{
        flex:1;
        text-align: center;
        color: #6C727E;
        font-weight: bold;
        font-size: 16px;
        padding: 0.5rem 0;
        border-bottom: 1px solid #D2E0EB;
        cursor: pointer;
      }
      .active{
        color: #3D56A3;
      }
    }
    .info{
      margin: 0.2rem 0 0;
      div{
        &:first-child{
          font-size: 20px;
          font-weight: bold;
          color: #122C5B;
          text-align: center;
        }
        &:nth-child(2){
          font-size: 16px;
          color: #4676B1;
          text-align: center;
          margin: 0 0 0.3rem;
        }
        &:nth-child(3),&:nth-child(4),&:nth-child(5){
          font-size: 14px;
          color: #6C727E;
          margin: 0.1rem 8%;
          span{
            color: #6A98D1;
            font-size: 16px;
          }
        }
      }
      .img-about{
        margin: 0.2rem 1%;
        width: 98%;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .img-prosess{
        width: 100%;
        background-color: #FAFAFA;
        padding: 0.4rem 0;
        img{
          width: 90%;
          height: 100%;
          margin: 0.5rem auto;
          display: flex;
          justify-content: center;
        }
      }
    }
    .culture{
      background-image: url('../assets/imgs/culture-bg.png');
      background-size: 100% 100%;
      padding: 0.1rem 0 15%;
      .info-li{
        margin: 0.2rem 8%;
        div{
          &:first-child{
            font-size: 20px;
            font-weight: bold;
            color: #6C727E;
            margin-bottom: 0.1rem;
          }
          &:nth-child(2){
            font-size: 14px;
            color: #6C727E;
            span{
              color: #6A98D1;
              font-size: 16px;
            }
          }
        }
      }
      .detail{
        margin: 0 8%;
        .detail-title{
          font-size: 20px;
          font-weight: bold;
          color: #6C727E;
          margin-bottom: 0.1rem;
        }
        .detail-contain{
          >div{
            &:nth-child(1){
              font-size: 16px;
              color: #6A98D1;
            }
            &:nth-child(2){
              display: flex;
              color: #6C727E;
              font-size: 16px;
              margin: 0.05rem 0;
              >div{
                margin-right: 0.1rem;
              }
            }
          }
        }
      }
    }
    .partners{
      margin: 0.1rem 0 0;
      .partners-list{
        margin: 0 8%;
        .partner-title{
          font-size: 20px;
          font-weight: bold;
          color: #546DB9;
          border-bottom: 1px solid #E6F0FF;
          padding: 0.25rem;
          text-align: center;
        }
        .partner-contain{
          display: flex;
          flex-wrap: wrap;
          .item{
            width: 33.3%;
            text-align: center;
            margin: 0.3rem 0;
            img{
              width: 1rem;
              height: 1rem;
              margin-bottom: 0.08rem;
            }
          }
        }
      }
    }
  }
}
}
@media screen and (min-width: 768px){
  .banner{
    width: 100%;
    height: 5rem;
    position: relative;
     .banner-info{
      position: absolute;
      top:40%;
      left: 8%;
      color: #fff;
      div{
        &:first-child{
          font-size: 26px;
        }
        &:nth-child(2){
          font-size: 18px;
        }
        &:nth-child(3){
          font-size: 16px;
          margin-top: 0.1rem;
        }
      }
    }
  }
  .about-us{
  .banner{
   
    img{
      width: 100%;
      height: 100%;
    }
   
  }
  .title{
    font-size: 24px;
    font-weight: bold;
    color: #122C5B;
    text-align: center;
  }
  .title-eng{
    font-size: 16px;
    color: #4676B1;
    text-align: center;
    margin: 0 0 0.2rem;
  }
  .abount-contain{
    width: 100%;
    .tab-list{
      display: flex;
      div{
        flex:1;
        text-align: center;
        color: #6C727E;
        font-weight: bold;
        font-size: 18px;
        padding: 0.2rem 0;
        border-bottom: 1px solid #D2E0EB;
        cursor: pointer;
      }
      .active{
        color: #3D56A3;
      }
    }
    .info{
      margin: 0.1rem 0 0;
      div{
        &:first-child{
          font-size: 24px;
          font-weight: bold;
          color: #122C5B;
          text-align: center;
        }
        &:nth-child(2){
          font-size: 16px;
          color: #4676B1;
          text-align: center;
          margin: 0 0 0.2rem;
        }
        &:nth-child(3),&:nth-child(4),&:nth-child(5){
          font-size: 14px;
          color: #6C727E;
          margin: 0.1rem 8%;
          span{
            color: #6A98D1;
            font-size: 16px;
          }
        }
      }
      .img-about{
        margin: 0.2rem 8%;
        width: 84%;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .img-prosess{
        width: 100%;
        background-color: #FAFAFA;
        padding: 0.4rem 0;
        img{
          width: 60%;
          height: 100%;
          margin: 0.5rem auto;
          display: flex;
          justify-content: center;
        }
      }
    }
    .culture{
      background-image: url('../assets/imgs/culture-bg.png');
      background-size: 100% 100%;
      padding: 0.1rem 0 15%;
      .info-li{
        margin: 0.2rem 8%;
        div{
          &:first-child{
            font-size: 20px;
            font-weight: bold;
            color: #6C727E;
            margin-bottom: 0.1rem;
          }
          &:nth-child(2){
            font-size: 14px;
            color: #6C727E;
            span{
              color: #6A98D1;
              font-size: 16px;
            }
          }
        }
      }
      .detail{
        margin: 0 8%;
        .detail-title{
          font-size: 20px;
          font-weight: bold;
          color: #6C727E;
          margin-bottom: 0.1rem;
        }
        .detail-contain{
          >div{
            &:nth-child(1){
              font-size: 16px;
              color: #6A98D1;
            }
            &:nth-child(2){
              display: flex;
              color: #6C727E;
              font-size: 16px;
              margin: 0.05rem 0;
              >div{
                margin-right: 0.1rem;
              }
            }
          }
        }
      }
    }
    .partners{
      margin: 0.1rem 0 0;
      .partners-list{
        margin: 0 8%;
        .partner-title{
          font-size: 24px;
          font-weight: bold;
          color: #546DB9;
          border-bottom: 1px solid #E6F0FF;
          padding: 0.25rem;
          text-align: center;
        }
        .partner-contain{
          display: flex;
          flex-wrap: wrap;
          .item{
            width: 16.6%;
            text-align: center;
            margin: 0.15rem 0;
            img{
              width: 0.5rem;
              height: 0.5rem;
              margin-bottom: 0.08rem;
            }
          }
        }
      }
    }
  }
}
}
@media screen and (min-width: 1200px){
  .banner{
    width: 100%;
    height: 3rem;
    position: relative;
     .banner-info{
      position: absolute;
      top:40%;
      left: 8%;
      color: #fff;
      div{
        &:first-child{
          font-size: 26px;
        }
        &:nth-child(2){
          font-size: 18px;
        }
        &:nth-child(3){
          font-size: 16px;
          margin-top: 0.1rem;
        }
      }
    }
  }
  .about-us{
  .banner{
   
    img{
      width: 100%;
      height: 100%;
    }
   
  }
  .title{
    font-size: 24px;
    font-weight: bold;
    color: #122C5B;
    text-align: center;
  }
  .title-eng{
    font-size: 16px;
    color: #4676B1;
    text-align: center;
    margin: 0 0 0.2rem;
  }
  .abount-contain{
    width: 100%;
    .tab-list{
      display: flex;
      div{
        flex:1;
        text-align: center;
        color: #6C727E;
        font-weight: bold;
        font-size: 18px;
        padding: 0.2rem 0;
        border-bottom: 1px solid #D2E0EB;
        cursor: pointer;
      }
      .active{
        color: #3D56A3;
      }
    }
    .info{
      margin: 0.1rem 0 0;
      div{
        &:first-child{
          font-size: 24px;
          font-weight: bold;
          color: #122C5B;
          text-align: center;
        }
        &:nth-child(2){
          font-size: 16px;
          color: #4676B1;
          text-align: center;
          margin: 0 0 0.2rem;
        }
        &:nth-child(3),&:nth-child(4),&:nth-child(5){
          font-size: 14px;
          color: #6C727E;
          margin: 0.1rem 8%;
          span{
            color: #6A98D1;
            font-size: 16px;
          }
        }
      }
      .img-about{
        margin: 0.2rem 8%;
        width: 84%;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .img-prosess{
        width: 100%;
        background-color: #FAFAFA;
        padding: 0.4rem 0;
        img{
          width: 60%;
          height: 100%;
          margin: 0.5rem auto;
          display: flex;
          justify-content: center;
        }
      }
    }
    .culture{
      background-image: url('../assets/imgs/culture-bg.png');
      background-size: 100% 100%;
      padding: 0.1rem 0 15%;
      .info-li{
        margin: 0.2rem 8%;
        div{
          &:first-child{
            font-size: 20px;
            font-weight: bold;
            color: #6C727E;
            margin-bottom: 0.1rem;
          }
          &:nth-child(2){
            font-size: 14px;
            color: #6C727E;
            span{
              color: #6A98D1;
              font-size: 16px;
            }
          }
        }
      }
      .detail{
        margin: 0 8%;
        .detail-title{
          font-size: 20px;
          font-weight: bold;
          color: #6C727E;
          margin-bottom: 0.1rem;
        }
        .detail-contain{
          >div{
            &:nth-child(1){
              font-size: 16px;
              color: #6A98D1;
            }
            &:nth-child(2){
              display: flex;
              color: #6C727E;
              font-size: 16px;
              margin: 0.05rem 0;
              >div{
                margin-right: 0.1rem;
              }
            }
          }
        }
      }
    }
    .partners{
      margin: 0.1rem 0 0;
      .partners-list{
        margin: 0 8%;
        .partner-title{
          font-size: 24px;
          font-weight: bold;
          color: #546DB9;
          border-bottom: 1px solid #E6F0FF;
          padding: 0.25rem;
          text-align: center;
        }
        .partner-contain{
          display: flex;
          flex-wrap: wrap;
          .item{
            width: 16.6%;
            text-align: center;
            margin: 0.15rem 0;
            img{
              width: 0.5rem;
              height: 0.5rem;
              margin-bottom: 0.08rem;
            }
          }
        }
      }
    }
  }
}
}
.about-us{
  padding-top: 70px;
  .nav-title{
    position: fixed;
    top:0;
    z-index: 100;
  }
}

</style>

